/** @jsxRuntime classic */
/** @jsx jsx */
import { Collapsible, colors } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import { domainResolver } from '../../../utils';
import { useQuotationTranslation } from '../../../utils/useQuotationTranslation';

const MainMenu = () => {
  const { t } = useQuotationTranslation();
  const menuItems = {
    ar: [
      {
        title: 'Cotizar seguro',
        items: [
          {
            label: 'Seguro para auto',
            link: `https://123seguro.com.ar/seguros/${t('products.car')}/cotizar`,
          },
          { label: 'Seguro de vida', link: 'https://vida.123seguro.com' },
        ],
      },
      {
        title: 'Mis seguros',
        items: [{ label: 'Acceder', link: 'https://123seguro.com.ar/autogestion/acceder2' }],
      },
      {
        title: 'Ayuda',
        items: [
          {
            label: 'Preguntas frecuentes',
            link: 'https://www.123seguro.com.ar/preguntas-frecuentes',
          },
          { label: 'Contacto', link: 'https://www.123seguro.com.ar/contacto' },
        ],
      },
      {
        title: 'Sobre123',
        items: [
          { label: 'Home', link: 'https://www.123seguro.com.ar/' },
          { label: 'Nosotros', link: 'https://www.123seguro.com.ar/nosotros' },
          { label: 'Blog', link: 'https://blog.123seguro.com/' },
        ],
      },
      {
        title: 'Aseguradoras',
        items: [
          { label: 'Sura', link: 'http://123seguro.com.ar/aseguradoras/sura' },
          { label: 'Galeno', link: 'http://123seguro.com.ar/aseguradoras/galeno' },
          { label: 'Sancor', link: 'http://123seguro.com.ar/aseguradoras/sancor' },
          { label: 'Provincia', link: 'http://123seguro.com.ar/aseguradoras/provincia' },
          {
            label: 'Mercantil Andina',
            link: 'http://123seguro.com.ar/aseguradoras/mercantil-andina',
          },
          { label: 'Zurich', link: 'http://123seguro.com.ar/aseguradoras/zurich' },
          { label: 'Mapfre', link: 'http://123seguro.com.ar/aseguradoras/mapfre' },
          { label: 'Orbis', link: 'http://123seguro.com.ar/aseguradoras/orbis' },
          { label: 'Allianz', link: 'http://123seguro.com.ar/aseguradoras/allianz' },
          { label: 'Meridional', link: 'http://123seguro.com.ar/aseguradoras/meridional' },
          { label: 'Prudencia', link: 'http://123seguro.com.ar/aseguradoras/prudencia' },
        ],
      },
    ],
    cl: [
      {
        title: 'Cotizar seguro',
        items: [
          {
            label: 'Seguro para auto',
            link: `https://123seguro.cl/seguros/${t('products.car')}/cotizar`,
          },
        ],
      },
      {
        title: 'Ayuda',
        items: [
          {
            label: 'Preguntas frecuentes',
            link: 'https://www.123seguro.cl/preguntas-frecuentes',
          },
          { label: 'Contacto', link: 'https://www.123seguro.cl/contacto' },
        ],
      },
      {
        title: 'Sobre123',
        items: [
          { label: 'Home', link: 'https://www.123seguro.cl/' },
          { label: 'Nosotros', link: 'https://www.123seguro.cl/nosotros' },
          { label: 'Blog', link: 'https://blog.123seguro.com/' },
        ],
      },
      {
        title: 'Aseguradoras',
        items: [
          {
            label: 'BCI',
            link: 'https://www.123seguro.cl/aseguradoras/bci',
          },
          {
            label: 'Mapfre',
            link: 'https://www.123seguro.cl/aseguradoras/mapfre',
          },
          {
            label: 'Reale',
            link: 'https://www.123seguro.cl/aseguradoras/reale',
          },
          {
            label: 'Sura',
            link: 'https://www.123seguro.cl/aseguradoras/sura',
          },
          {
            label: 'Zenit',
            link: 'https://www.123seguro.cl/aseguradoras/zenit',
          },
          {
            label: 'Zurich Chile',
            link: 'https://www.123seguro.cl/aseguradoras/zurich-chile',
          },
        ],
      },
    ],
    co: [],
    br: [],
    mx: [],
  };
  const styles = {
    'button .title': {
      marginBottom: 0,
    },
    '.menu-link': {
      fontSize: 13,
      color: colors.gray.dark,
      width: '100%',
      padding: '12px 40px 12px 24px',
      borderLeft: `2px solid ${colors.cyan.dark}`,
      margin: '2px 0',
      paddingRight: 12,
      paddingLeft: 16,
    },
  };
  const renderMenu = () => {
    return menuItems[domainResolver()].map((group) => {
      return (
        <Collapsible key={group.title} className="collapsible" type="menu" title={group.title}>
          {group.items.map((item) => (
            <a
              key={item.label}
              className="menu-link"
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              title={item.label}
            >
              {item.label}
            </a>
          ))}
        </Collapsible>
      );
    });
  };

  return (
    <section css={styles} data-testid="main-menu-component">
      {renderMenu()}
    </section>
  );
};

export default MainMenu;
