import { RESET_STATE } from '../../../wizard/store/wizard.action-types';
import { EMAIL_SAVE } from './email.action-types';

const INITIAL_STATE = {
  selected: null,
};

const emailReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        selected: null,
      };
    case EMAIL_SAVE:
      return { ...state, selected: action.payload };
    default:
      return state;
  }
};

export default emailReducer;
