/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { InactiveDefaultPage } from '@123-front/ui-kit';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { analyticsPushEvent } from '../../analytics/store/analytics.actions';

const InactivePage = ({ title, analyticsPushEvent, landingUrl, header }) => {
  useEffect(() => {
    if (landingUrl) {
      analyticsPushEvent(
        'trackError',
        `TypeError - ${landingUrl}`,
        'error-campo',
        null,
        'cotizador',
      );
    }
  }, [analyticsPushEvent, landingUrl]);

  return (
    <div data-testid="error-page-component">
      <InactiveDefaultPage header={header} title={title} />
    </div>
  );
};

InactivePage.propTypes = {
  title: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  analyticsPushEvent: PropTypes.func,
  landingUrl: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  landingUrl: state.main.mkt.landingUrl,
});

export default connect(mapStateToProps, { analyticsPushEvent })(InactivePage);
