import { v4 as uuidv4 } from 'uuid';

export const getUUID = () => {
  const key = 'x-client-id';
  if (!localStorage.getItem(key)) {
    localStorage.setItem(key, uuidv4());
  }
  const id = localStorage.getItem(key);
  return id;
};
