export const getMktObject = (options) => {
  const directReferer = '';
  const paramsString = window.location.search.replace('?', '');
  const searchParams = new URLSearchParams(paramsString);
  const originalParamsString = paramsString
    ? JSON.parse(
        '{"' +
          decodeURI(paramsString).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') +
          '"}',
      )
    : {};
  const mkt = {};
  if (!options?.partner) {
    mkt.source = searchParams.get('utm_source')
      ? searchParams.get('utm_source').toLowerCase()
      : null;
    mkt.medium = searchParams.get('utm_medium')
      ? searchParams.get('utm_medium').toLowerCase()
      : null;
  }
  mkt.gclid = searchParams.get('gclid') ? searchParams.get('gclid') : null;
  mkt.referer = searchParams.get('referer') || document.referrer || directReferer;
  mkt.landingUrl = window.location.href;
  mkt.queryParams = originalParamsString;
  return mkt;
};
