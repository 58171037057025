import { useEffect, useState } from 'react';
import { getPartner } from './fluxes';

const defaultConfig = {
  title: '123Seguro',
  description:
    'En 123Seguro vas a poder encontrar una gran variedad de seguros para autos y contratar el que mejor se adapte a tus necesidades. ¡Cotizá tu seguro online!',
  image: 'https://branding.123seguro.com/logotypes/123/color/logo_123seguro.svg',
  favicons: {
    favicon16: '/wizard/favicon-16x16.png',
    favicon32: '/wizard/favicon-32x32.png',
    favicon152: '/wizard/apple-touch-icon.png',
    favicon180: '/wizard/apple-touch-icon.png',
    faviconIco: '/wizard/favicon.ico',
  },
  manifest: '/wizard/site.webmanifest',
};

const partnerSEOConfig = {
  serasa: {
    title: 'Segurança, tranquilidade e proteção para você em todos os momentos!',
    description:
      'Seguro Acidentes Pessoais. Uma parceria entre Serasa e Prudential que busca proteger nosso maior bem, que é você.',
    image: 'https://branding.123seguro.com/favicons/prudential.png',
    favicons: {
      favicon16: 'https://branding.123seguro.com/favicons/prudential-16x16.png',
      favicon32: 'https://branding.123seguro.com/favicons/prudential-32x32.png',
      favicon152: 'https://branding.123seguro.com/favicons/prudential-152x152.png',
      favicon180: 'https://branding.123seguro.com/favicons/prudential-180x180.png',
      faviconIco: 'https://branding.123seguro.com/favicons/prudential.ico',
    },
    manifest: 'https://www.prudentialparceiros.com.br/site.webmanifest',
  },
};

export const useSEOConfig = () => {
  const [seoConfig, setSeoConfig] = useState(null);
  const partner = getPartner();
  useEffect(() => {
    setSeoConfig(partnerSEOConfig[partner] || defaultConfig);
  }, [partner]);

  return seoConfig;
};
