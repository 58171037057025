import PropTypes from 'prop-types';

const optionItemPropTypes = PropTypes.shape({
  id: PropTypes.number,
  display: PropTypes.string,
});

const fieldPropTypes = PropTypes.shape({
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  validation: PropTypes.shape({
    required: PropTypes.bool,
    regex: PropTypes.string,
  }),
  items: PropTypes.arrayOf(optionItemPropTypes),
});

const logoPropTypes = PropTypes.shape({
  path: PropTypes.string,
  visibility: PropTypes.bool,
});

const termsPropTypes = PropTypes.shape({
  text: PropTypes.string,
  link: PropTypes.string,
});

const baseContactConfigPropTypes = PropTypes.shape({
  allow: PropTypes.bool.isRequired,
  number: PropTypes.string,
  display: PropTypes.string,
});

const contactConfigPropTypes = PropTypes.shape({
  phone: baseContactConfigPropTypes,
  whatsapp: baseContactConfigPropTypes,
});

export const partnerConfigPropTypes = PropTypes.shape({
  keyName: PropTypes.string,
  name: PropTypes.string,
  slug: PropTypes.string,
  wizardFlux: PropTypes.string,
  country: PropTypes.string,
  show123Logo: PropTypes.bool,
  contactConfig: contactConfigPropTypes,
  logo: logoPropTypes,
  fields: PropTypes.arrayOf(fieldPropTypes),
  terms: termsPropTypes,
  showWhatsappFloatingBtn: PropTypes.bool,
  translationOverrides: PropTypes.object,
});
