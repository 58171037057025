/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/core';
import { connect } from 'react-redux';
import { titleFont, respondTo, Divider, Button } from '@123-front/ui-kit';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { layout, container } from '../../main/styles/layout';
import { analyticsPushEvent, analyticsPushPage } from '../../analytics/store/analytics.actions';
import { useQuotationTranslation } from '../../../utils/useQuotationTranslation';
import ContinueWhatsapp from '../../main/components/ContinueWhatsapp';
import BreadcrumbSteps from '../../../shared/detail/components/BreadcrumbSteps';
import { getFilteredAdditionalData } from '../../analytics/analytics.service';
import { commonKeysInAdditionalData } from '../../analytics/analytics.config';
import history from '../../main/store/history';
import { useBaseUrl } from '../../../utils/useBaseUrl';
import { brandPropTypes } from '../brand/brand.proptypes';
import { useContactConfig } from '../../../utils/useContactConfig';

const styles = {
  ...layout,
  ...container(552),
  [respondTo('xs', 'sm')]: {
    marginTop: 24,
  },

  '.results-breadcrumb': {
    textAlign: 'center',
    div: {
      display: 'inline',
    },
  },
  '.divider-top': {
    marginTop: 40,
  },
  '.divider-bottom': {
    marginBottom: 0,
  },
  '.logo-container': {
    textAlign: 'center',
    '.noresults-img': {
      marginTop: 30,
      marginBottom: 30,
    },
  },
  '.noresult-text': {
    ...titleFont('xs', 'serif'),
    textAlign: 'center',
    marginBottom: 40,
  },
  '.whatsapp-button': {
    textAlign: 'center',
  },
  '.contact-link': {
    marginTop: 32,
    textAlign: 'center',
  },
};

const NoResults = ({ analytics, brand, analyticsPushEvent, analyticsPushPage }) => {
  const baseUrl = useBaseUrl();
  if (brand === null) {
    history.push(`${baseUrl}${window.location.search}`);
  }

  const { t } = useQuotationTranslation();
  const showContact = () => window.open(t('thanks.contactUrl'));
  const { whatsappConfig, allowContactLink } = useContactConfig();

  useEffect(() => {
    analyticsPushEvent(
      'trackCtz',
      'no-asegurable',
      'cotizacion',
      getFilteredAdditionalData(analytics.pageAdditionalData, [
        ...commonKeysInAdditionalData,
        'lead_id',
        'no_asegurable',
        'no_asegurable_id',
      ]),
      'cotizador',
    );
    analyticsPushPage('car', 'noresults');
  }, [analyticsPushEvent, analytics.pageAdditionalData, analyticsPushPage]);

  return (
    <section id="no-results-selection" css={styles} data-testid="no-results-component">
      <div>
        <Divider className={'divider-top'} />
        {brand && <BreadcrumbSteps className="results-breadcrumb" resetResults={true} />}
        <Divider className={'divider-bottom'} />
        <div className={'logo-container'}>
          <img className="noresults-img" alt="contactar" src={t('results.noResultsImg')} />
        </div>
        <div className={'noresult-text'}>{t('results.noResults')}</div>
        {whatsappConfig.allow && (
          <div className={'container'}>
            <ContinueWhatsapp mode="button" step={'noresults'} />
          </div>
        )}
        {allowContactLink && (
          <div className={'contact-link'}>
            <Button variant={'link'} onClick={showContact}>
              {t('thanks.contactLink')}
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};

NoResults.propTypes = {
  analytics: PropTypes.object,
  brand: brandPropTypes,
  analyticsPushEvent: PropTypes.func,
  analyticsPushPage: PropTypes.func,
};

const mapStateToProps = (state) => ({
  brand: state.car.lead.selected.brand,
  analytics: state.analytics,
});

export default connect(mapStateToProps, { analyticsPushEvent, analyticsPushPage })(NoResults);
