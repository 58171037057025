import { RESET_STATE } from '../../../wizard/store/wizard.action-types';
import { JUST_NAME_SAVE } from './justName.action-types';

const INITIAL_STATE = {
  selected: {
    name: null,
    isCompany: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        selected: {
          name: null,
          isCompany: false,
        },
      };
    case JUST_NAME_SAVE:
      return {
        ...state,
        selected: action.payload,
      };
    default:
      return state;
  }
};
