const config = {
  brand: {
    action: 'loadBrands',
  },
  model: {
    action: 'loadModels',
  },
  year: {
    action: 'loadYears',
  },
  version: {
    action: 'loadVersions',
  },
  categories: {
    action: 'loadCategories',
  },
  marketSectors: {
    action: 'loadMarketSector',
  },
  surface: {
    action: 'loadSurface',
  },
  dependents: {
    action: 'loadDependents',
  },
  expenses: {
    action: 'loadExpenses',
  },
  justLocaly: {
    action: 'loadJustLocalities',
  },
  justProvince: {
    action: 'loadProvinces',
  },
  location: {
    action: 'loadProvinces',
  },
  province: {
    action: 'loadProvinces',
  },
  partner: {
    action: 'loadPartner',
  },
  partnerentry: {
    action: 'loadPartner',
  },
};

export default config;
