import { detectActiveFlux, getFluxIndex } from './fluxes';

let searchByBrandWithValidCPF = '';
let searchByPlateWithValidCPFWithMainDriver = '';
let searchByPlateWithValidCPFWithoutMainDriver = '';
let searchByPlateWithInvalidCPFWithoutMainDriver = '';
let searchByPlateWithInvalidCPF = '';
let searchByBrandWithInvalidCPF = '';
let searchByBrandNoPlate = '';
let searchByPlateWithCNPJ = '';
let searchByBrandWithCNPJ = '';

let fluxesWithStartPlate = [];
let fluxesWithStartBrand = [];

let fluxes = [];

/**
 * Load fluxes information
 * @param  {Array<object>} fluxesParam
 * @returns {}
 */
export const loadFluxesInformation = (fluxesParam) => {
  fluxes = fluxesParam;
  searchByBrandWithValidCPF = getFluxIndex(fluxes, 'search-by-brand');
  searchByPlateWithValidCPFWithMainDriver = getFluxIndex(
    fluxes,
    'search-by-plate-with-valid-CPF-with-main-driver',
  );
  searchByPlateWithValidCPFWithoutMainDriver = getFluxIndex(
    fluxes,
    'search-by-plate-with-valid-CPF-without-main-driver',
  );
  searchByPlateWithInvalidCPFWithoutMainDriver = getFluxIndex(
    fluxes,
    'search-by-plate-with-invalid-CPF-without-main-driver',
  );
  searchByPlateWithInvalidCPF = getFluxIndex(fluxes, 'search-by-plate-with-invalid-CPF');
  searchByBrandWithInvalidCPF = getFluxIndex(fluxes, 'search-by-brand-with-invalid-CPF');
  searchByBrandNoPlate = getFluxIndex(fluxes, 'search-by-brand-no-plate');
  searchByPlateWithCNPJ = getFluxIndex(fluxes, 'search-by-plate-with-CNPJ');
  searchByBrandWithCNPJ = getFluxIndex(fluxes, 'search-by-brand-with-CNPJ');

  fluxesWithStartPlate = [
    searchByPlateWithValidCPFWithMainDriver,
    searchByPlateWithValidCPFWithoutMainDriver,
    searchByPlateWithInvalidCPFWithoutMainDriver,
    searchByPlateWithInvalidCPF,
    searchByPlateWithCNPJ,
  ];
  fluxesWithStartBrand = [
    searchByBrandWithValidCPF,
    searchByBrandWithInvalidCPF,
    searchByBrandNoPlate,
    searchByBrandWithCNPJ,
  ];
};

/**
 * Get steps list (used by getStepAndFluxIndex and getNextStepByLegalId)
 * @param  {Array<object>} steps
 * @returns {Array<object>} get steps in one flat list
 */
export const getStepsList = (steps) => {
  return steps
    .map((module) => {
      return module.moduleSteps;
    })
    .flat();
};

/**
 * Get next step when it exists an error in document's search
 * @param  {object} params
 * @param  {string} params.legalIdType
 * @param  {boolean} params.isMainDriver
 * @param  {function} params.beforeChangeStep
 * @returns {object} from getStepAndFluxIndexCPF function
 */
export const getNextStepWithErrorInSearch = ({
  legalIdType,
  isMainDriver,
  beforeChangeStep = (_) => {},
}) => {
  if (legalIdType === 'CPF') {
    return getStepAndFluxIndexCPF({ stepName: 'justName', isMainDriver, beforeChangeStep });
  } else {
    return getStepAndFluxIndexCNPJ({ stepName: 'justName', beforeChangeStep });
  }
};

/**
 * Get step index and flux index of CPF
 * @param  {object} params
 * @param  {string} params.stepName
 * @param  {boolean} params.isMainDriver
 * @param  {function} params.beforeChangeStep
 * @returns {object} about step id and flux id
 */
export const getStepAndFluxIndexCPF = ({
  stepName,
  isMainDriver,
  beforeChangeStep = (_) => {},
}) => {
  let currentFlux = detectActiveFlux();
  let nextFlux = '';
  let nextStepNumber = 0;

  switch (true) {
    case stepName === 'maritalStatus' && fluxesWithStartPlate.includes(currentFlux):
      nextFlux = searchByPlateWithValidCPFWithMainDriver;
      break;
    case stepName === 'maritalStatus' && !fluxesWithStartPlate.includes(currentFlux):
      nextFlux = searchByBrandWithValidCPF;
      break;
    case stepName === 'justName' && fluxesWithStartPlate.includes(currentFlux) && isMainDriver:
      nextFlux = searchByPlateWithInvalidCPF;
      break;
    case stepName === 'justName' && !fluxesWithStartPlate.includes(currentFlux) && isMainDriver:
      nextFlux = searchByBrandWithInvalidCPF;
      break;
    case stepName === 'justName' && !isMainDriver:
      nextFlux = searchByPlateWithInvalidCPFWithoutMainDriver;
      break;
    case stepName === 'principalMotorista':
      nextFlux = searchByPlateWithValidCPFWithoutMainDriver;
      break;
    case 'searchByLegalId':
      nextFlux = currentFlux;
      break;
    default:
      nextFlux = '0';
      nextStepNumber = 0;
      break;
  }
  nextStepNumber = getStepsList(fluxes[nextFlux].steps).indexOf(stepName);
  beforeChangeStep();
  return {
    stepId: nextStepNumber,
    fluxId: nextFlux,
    name: stepName,
    type: 'CPF',
  };
};

/**
 * Get step index and flux index of CNPJ
 * @param  {object} params
 * @param  {string} params.stepName
 * @param  {function} params.beforeChangeStep
 * @returns {object} about step id and flux id
 */
export const getStepAndFluxIndexCNPJ = ({ stepName, beforeChangeStep = (_) => {} }) => {
  let currentFlux = detectActiveFlux();
  let nextFlux = '';
  let nextStepNumber = 0;

  switch (true) {
    case stepName === 'justName' && fluxesWithStartPlate.includes(currentFlux):
      nextFlux = searchByPlateWithCNPJ;
      break;
    case stepName === 'justName' && !fluxesWithStartPlate.includes(currentFlux):
      nextFlux = searchByBrandWithCNPJ;
      break;
    default:
      nextFlux = '0';
      nextStepNumber = 0;
      break;
  }
  nextStepNumber = getStepsList(fluxes[nextFlux].steps).indexOf(stepName);
  beforeChangeStep();
  return {
    stepId: nextStepNumber,
    fluxId: nextFlux,
    name: stepName,
    type: 'CNPJ',
  };
};

/**
 * Get next step from searchByLegalId step
 * @param  {object} params
 * @param  {string} params.legalIdType
 * @param  {function} params.beforeChangeStep
 * @returns {object} about step id and flux id
 */
export const getNextStepByLegalId = ({ legalIdType, beforeChangeStep = (_) => {} }) => {
  let currentFlux = detectActiveFlux();
  let nextFlux = currentFlux;
  if (fluxesWithStartBrand.includes(currentFlux)) {
    nextFlux = searchByBrandWithValidCPF;
  } else if (fluxesWithStartPlate.includes(currentFlux)) {
    nextFlux = searchByPlateWithValidCPFWithMainDriver;
  }
  let nextStepNumber = getStepsList(fluxes[nextFlux].steps).indexOf('personByLegalId');
  let name = 'personByLegalId';
  if (legalIdType === 'CNPJ') {
    if (fluxesWithStartBrand.includes(currentFlux)) {
      nextFlux = searchByBrandWithCNPJ;
    } else if (fluxesWithStartPlate.includes(currentFlux)) {
      nextFlux = searchByPlateWithCNPJ;
    }
    nextStepNumber = getStepsList(fluxes[nextFlux].steps).indexOf('justName');
    name = 'justName';
  }
  beforeChangeStep();
  return {
    stepId: nextStepNumber,
    fluxId: nextFlux,
    name,
    type: legalIdType,
  };
};
