import { getMail, getLeadId } from '../../../../../utils/mapper/messageText';

const getDetailText = (t, state) => {
  const lead = state.telecare.lead.selected;

  return t(`whatsapp.detail`, {
    name: lead.name.name,
    lead_id: getLeadId(lead),
    email: getMail(lead),
  });
};

const getThanksText = (t, state) => {
  const lead = state.telecare.lead.selected;

  return t(`whatsapp.thanks`, {
    name: lead.name.name,
    lead_id: getLeadId(lead),
    email: getMail(lead),
  });
};

export const getTelecareText = ({ t, state, step }) => {
  switch (step) {
    case 'detail':
      return getDetailText(t, state);
    case 'thanks':
      return getThanksText(t, state);
    default:
      return getDetailText(t, state);
  }
};
