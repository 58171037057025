import { JUST_LOCATIONS_SAVE } from './location.action-types';

const INITIAL_STATE = {
  selected: null,
};

const justLocationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case JUST_LOCATIONS_SAVE:
      return {
        ...state,
        selected: {
          ...state.selected,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default justLocationReducer;
