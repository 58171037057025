import { RESET_STATE } from '../../../wizard/store/wizard.action-types';
import { PHONE_SAVE } from './phone.action-types';

const INITIAL_STATE = {
  selected: {
    countryCode: '',
    areaCode: '',
    number: '',
    observation: '',
  },
};

const phoneReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        selected: {
          countryCode: '',
          areaCode: '',
          number: '',
          observation: '',
        },
      };
    case PHONE_SAVE:
      return {
        ...state,
        selected: {
          ...state.selected,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default phoneReducer;
