import { defaultFlux } from '../../../utils/fluxes';
import { PARTNER_CONFIG_LOAD_FAIL } from '../../service/store/service.action-types';
import * as actions from './wizard.action-types';

const INITIAL_STATE = {
  product: undefined,
  completedStep: 0,
  currentStep: 1,
  totalSteps: undefined,
  directionForward: true,
  steps: [],
  riskType: 'auto',
  fluxes: [],
  activeFlux: null,
  isSheet: false,
  hasChangeFlux: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.GET_STEPS:
      return {
        ...state,
      };
    case actions.GET_STEPS_SUCCESS:
      return {
        ...state,
        ...action.payload,
      };
    case actions.GET_STEPS_FAIL:
      return {
        ...state,
      };
    case actions.NEXT_STEP:
      return {
        ...state,
        ...action.payload,
        directionForward: true,
        hasChangeFlux: false,
      };
    case actions.RESET_PROGRESS:
      return {
        ...state,
        ...action.payload,
        directionForward: false,
        hasChangeFlux: false,
        entryFlux: defaultFlux,
      };
    case actions.SET_RISK_TYPE:
      return {
        ...state,
        riskType: action.payload,
      };
    case actions.CHANGE_FLUX:
      return {
        ...state,
        ...action.payload,
        directionForward: false,
        hasChangeFlux: true,
      };
    case PARTNER_CONFIG_LOAD_FAIL:
      return {
        ...state,
        ...action.payload,
        entryFlux: defaultFlux,
        activeFlux: defaultFlux,
      };
    case actions.SAVE_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };
    case actions.RESET_STATE:
      return {
        product: undefined,
        completedStep: 0,
        currentStep: 1,
        totalSteps: undefined,
        directionForward: true,
        steps: [],
        riskType: 'auto',
        fluxes: [],
        activeFlux: null,
        hasChangeFlux: false,
      };
    default:
      return state;
  }
};
