/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useQuotationTranslation } from '../../../utils/useQuotationTranslation';
import { useBaseUrl } from '../../../utils/useBaseUrl';
import { analyticsPushEvent } from '../../analytics/store/analytics.actions';
import ErrorPage from './ErrorPage';

const Page404 = ({ analyticsPushEvent }) => {
  const { t } = useQuotationTranslation();
  const defaultFlux = '0';
  const baseUrl = useBaseUrl();

  const goToHome = () =>
    (window.location.href = `${baseUrl}/${defaultFlux}/${window.location.search}`);

  const pathName = useLocation().pathname;

  useEffect(() => {
    analyticsPushEvent(
      'trackError',
      'not found 404',
      'error-campo',
      { url: pathName },
      'cotizador',
    );
  }, [pathName, analyticsPushEvent]);

  return (
    <ErrorPage
      title={t('notFound.title')}
      buttonText={t('notFound.button')}
      onButtonClick={goToHome}
    />
  );
};
Page404.propTypes = {
  analyticsPushEvent: PropTypes.func,
};

export default connect(null, {
  analyticsPushEvent,
})(Page404);
