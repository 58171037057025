import { useSelector } from 'react-redux';

/**
 * useFluxObj
 * @description Return flux object
 * @param {string} fluxIndex
 * @returns {Flux} Flux object
 */
export const useFluxObj = (fluxIndex) => {
  const wizardState = useSelector((state) => state.wizard);
  if (wizardState.fluxes.length === 0) {
    return null;
  }

  return wizardState.fluxes.find((flux) => flux.index === fluxIndex);
};
