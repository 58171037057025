import main from './modules/main/store/reducer';
import wizard from './modules/wizard/store/wizard.reducer';
import person from './modules/person/person.reducer';
import contact from './modules/contact/contact.reducer';
import analytics from './modules/analytics/store/analytics.reducer';
import service from './modules/service/store/service.reducer';

const initialReducer = {
  main,
  wizard,
  person,
  contact,
  analytics,
  service,
};

export default initialReducer;
