import { MARITAL_STATUS_SAVE } from './maritalStatus.action-types';

const INITIAL_STATE = {
  selected: null,
};

const maritalStatusReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MARITAL_STATUS_SAVE:
      return { ...state, selected: action.payload };
    default:
      return state;
  }
};

export default maritalStatusReducer;
