import PropTypes from 'prop-types';

export const legalIdTypePropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
});

export const legalIdPropType = PropTypes.shape({
  type: legalIdTypePropType,
  number: PropTypes.string,
});

export const legalIdTypesPropType = PropTypes.arrayOf(legalIdTypePropType);
