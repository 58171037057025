import {
  BRANDS_LOAD,
  BRANDS_LOAD_FAIL,
  BRANDS_LOAD_SUCCESS,
  MODELS_LOAD,
  MODELS_LOAD_FAIL,
  MODELS_LOAD_SUCCESS,
  YEARS_LOAD,
  YEARS_LOAD_FAIL,
  YEARS_LOAD_SUCCESS,
  VERSIONS_LOAD,
  VERSIONS_LOAD_FAIL,
  VERSIONS_LOAD_SUCCESS,
  CATEGORIES_LOAD,
  CATEGORIES_LOAD_SUCCESS,
  CATEGORIES_LOAD_FAIL,
  MARKET_SECTOR_LOAD,
  MARKET_SECTOR_LOAD_FAIL,
  MARKET_SECTOR_LOAD_SUCCESS,
  SURFACE_LOAD,
  SURFACE_LOAD_FAIL,
  SURFACE_LOAD_SUCCESS,
  DEPENDENT_LOAD,
  DEPENDENT_LOAD_FAIL,
  DEPENDENT_LOAD_SUCCESS,
  EXPENSES_LOAD,
  EXPENSES_LOAD_SUCCESS,
  EXPENSES_LOAD_FAIL,
  PROVINCES_LOAD,
  PROVINCES_LOAD_FAIL,
  PROVINCES_LOAD_SUCCESS,
  JUST_LOCALITIES_LOAD,
  JUST_LOCALITIES_LOAD_FAIL,
  JUST_LOCALITIES_LOAD_SUCCESS,
  PARTNER_CONFIG_LOAD,
  PARTNER_CONFIG_LOAD_FAIL,
  PARTNER_CONFIG_LOAD_SUCCESS,
} from './service.action-types';

const INITIAL_STATE = {
  loading: false,
  brand: {
    brands: [],
  },
  model: {
    models: [],
  },
  year: {
    years: [],
  },
  version: {
    versions: [],
  },
  categories: {
    categories: {},
  },
  marketSectors: {
    marketSectors: [],
  },
  surface: {
    surfaces: [],
  },
  dependents: {
    dependentses: [],
  },
  expenses: {
    expenses: [],
  },
  justLocaly: {
    localities: [],
  },
  justProvince: {
    provinces: [],
  },
  location: {
    provinces: [],
  },
  partnerConfig: {
    data: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BRANDS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case BRANDS_LOAD_FAIL:
      return {
        ...state,
        loading: false,
        brand: {
          ...state.brand,
          finished: true,
        },
      };
    case BRANDS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        brand: {
          ...state.brand,
          brands: action.payload,
        },
      };
    case MODELS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case MODELS_LOAD_FAIL:
      return {
        ...state,
        loading: false,
      };
    case MODELS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        model: {
          ...state.model,
          models: action.payload,
        },
      };
    case YEARS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case YEARS_LOAD_FAIL:
      return {
        ...state,
        loading: false,
      };
    case YEARS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        year: {
          ...state.year,
          years: action.payload,
        },
      };
    case VERSIONS_LOAD:
      return {
        ...state,
        loading: true,
      };
    case VERSIONS_LOAD_FAIL:
      return {
        ...state,
        loading: false,
      };
    case VERSIONS_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        version: {
          ...state.versions,
          versions: action.payload,
        },
      };
    case CATEGORIES_LOAD:
      return {
        ...state,
        loading: true,
      };
    case CATEGORIES_LOAD_FAIL:
      return {
        ...state,
        loading: false,
      };
    case CATEGORIES_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: {
          ...state.categories,
          categories: action.payload,
        },
      };
    case MARKET_SECTOR_LOAD:
      return {
        ...state,
        loading: true,
      };
    case MARKET_SECTOR_LOAD_FAIL:
      return {
        ...state,
        loading: false,
      };
    case MARKET_SECTOR_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        marketSectors: {
          ...state.marketSectors,
          marketSectors: action.payload,
        },
      };
    case SURFACE_LOAD:
      return {
        ...state,
        loading: true,
      };
    case SURFACE_LOAD_FAIL:
      return {
        ...state,
        loading: false,
      };
    case SURFACE_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        surface: {
          ...state.surface,
          surfaces: action.payload,
        },
      };
    case DEPENDENT_LOAD:
      return {
        ...state,
        loading: true,
      };
    case DEPENDENT_LOAD_FAIL:
      return {
        ...state,
        loading: false,
      };
    case DEPENDENT_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        dependents: {
          ...state.dependents,
          dependentses: action.payload,
        },
      };
    case EXPENSES_LOAD:
      return {
        ...state,
        loading: true,
      };
    case EXPENSES_LOAD_FAIL:
      return {
        ...state,
        loading: false,
      };
    case EXPENSES_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        expenses: {
          ...state.expenses,
          expenses: action.payload,
        },
      };
    case JUST_LOCALITIES_LOAD:
      return {
        ...state,
        loading: true,
      };
    case JUST_LOCALITIES_LOAD_FAIL:
      return {
        ...state,
        loading: false,
      };
    case JUST_LOCALITIES_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        justLocaly: {
          ...state.justLocaly,
          localities: action.payload,
        },
      };
    case PROVINCES_LOAD:
      return {
        ...state,
        loading: true,
      };
    case PROVINCES_LOAD_FAIL:
      return {
        ...state,
        loading: false,
      };
    case PROVINCES_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        justProvince: {
          ...state.justProvince,
          provinces: action.payload,
        },
        location: {
          ...state.location,
          provinces: action.payload,
        },
      };
    case PARTNER_CONFIG_LOAD:
      return {
        ...state,
        loading: true,
      };
    case PARTNER_CONFIG_LOAD_FAIL:
      return {
        ...state,
        loading: false,
      };
    case PARTNER_CONFIG_LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        partnerConfig: {
          ...state.partner,
          data: action.payload,
        },
      };
    default:
      return state;
  }
};
