import { MAIN_DRIVER_LEGAL_ID_SAVE } from './mainDriverLegalId.action-types';

const INITIAL_STATE = {
  selected: {
    type: null,
    number: null,
  },
};

const mainDriverLegalIdReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MAIN_DRIVER_LEGAL_ID_SAVE:
      return { ...state, selected: action.payload };
    default:
      return state;
  }
};

export default mainDriverLegalIdReducer;
