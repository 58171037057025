import * as actions from './action-types';

const INITIAL_STATE = {
  context: {
    countryName: null,
    whatsappNumber: {
      number: null,
      display: null,
    },
    customerSupport: {
      number: null,
      display: null,
    },
    phone: {
      countryCode: null,
    },
    legalIdTypes: [],
    birthdate: {
      limits: {
        min: null,
        age: null,
      },
    },
    plate: {
      showNotPlateOption: true,
    },
    oneTrustId: null,
  },
  properties: {},
  mkt: {
    source: null,
    medium: null,
    gclid: null,
    referer: '',
    landingUrl: '',
  },
  audit: {},
  baseUrl: '',
  didContextLoad: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actions.CONTEXT_LOAD:
      return {
        ...state,
      };
    case actions.CONTEXT_LOAD_SUCCESS:
      return {
        ...state,
        context: action.payload,
        didContextLoad: true,
      };
    case actions.CONTEXT_LOAD_FAIL:
      return {
        ...state,
        didContextLoad: true,
      };
    case actions.PROPERTIES_LOAD:
      return {
        ...state,
      };
    case actions.PROPERTIES_LOAD_SUCCESS:
      return {
        ...state,
        properties: action.payload,
      };
    case actions.PROPERTIES_LOAD_FAIL:
      return {
        ...state,
      };
    case actions.PARTNER_CONFIG_LOAD_SUCCESS:
      return {
        ...state,
        mkt: {
          ...state.mkt,
          source: action.payload.channel,
          medium: action.payload.campaign,
        },
      };
    case actions.MKT_SAVE:
      return {
        ...state,
        mkt: action.payload,
      };
    case actions.AUDIT_SAVE:
      return {
        ...state,
        audit: { ...state.audit, ...action.payload },
      };
    case actions.BASEURL_SAVE:
      return {
        ...state,
        baseUrl: action.payload,
      };
    default:
      return state;
  }
};
