import {
  PERSON_BY_LEGAL_ID_LOAD,
  PERSON_BY_LEGAL_ID_SUCCESS,
  PERSON_BY_LEGAL_ID_FAIL,
  PERSON_BY_LEGAL_ID_CLEAR,
  PERSON_SEARCH_NOT_FOUND,
  PERSON_BY_LEGAL_CLEAR_REQUEST_ANSWER,
} from './personByLegalId.action-types';

const INITIAL_STATE = {
  selected: null,
  searchNotFound: false,
  success: false,
  error: false,
  loading: false,
};

const personByLegalIdReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PERSON_BY_LEGAL_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        searchNotFound: false,
        selected: action.payload,
        success: true,
      };
    case PERSON_BY_LEGAL_ID_FAIL:
      return {
        ...state,
        selected: null,
        loading: false,
        error: true,
        success: false,
      };
    case PERSON_BY_LEGAL_ID_LOAD:
      return {
        ...state,
        loading: true,
      };
    case PERSON_BY_LEGAL_ID_CLEAR:
      return {
        searchNotFound: false,
        selected: null,
        loading: false,
        success: false,
      };
    case PERSON_SEARCH_NOT_FOUND:
      return {
        ...state,
        searchNotFound: true,
        success: false,
        error: true,
      };
    case PERSON_BY_LEGAL_CLEAR_REQUEST_ANSWER:
      return {
        ...state,
        success: false,
        error: false,
      };
    default:
      return state;
  }
};

export default personByLegalIdReducer;
