import { RESET_STATE } from '../../../wizard/store/wizard.action-types';
import {
  LOCALITIES_LOAD,
  LOCALITIES_LOAD_FAIL,
  LOCALITIES_LOAD_SUCCESS,
  LOCATIONS_SAVE,
  PROVINCE_SAVE,
} from './location.action-types';

const INITIAL_STATE = {
  localities: [],
  selected: null,
  isLoading: false,
};

const locationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOCALITIES_LOAD:
      return { ...state, isLoading: true };
    case LOCALITIES_LOAD_FAIL:
      return { ...state, isLoading: false };
    case LOCALITIES_LOAD_SUCCESS:
      return { ...state, isLoading: false, localities: action.payload };
    case PROVINCE_SAVE:
      return {
        ...state,
        selected: {
          ...action.payload,
        },
      };
    case LOCATIONS_SAVE:
      return {
        ...state,
        selected: {
          ...state.selected,
          ...action.payload,
        },
      };
    case RESET_STATE:
      return {
        ...state,
        selected: null,
      };
    default:
      return state;
  }
};

export default locationReducer;
