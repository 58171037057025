/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/core';
import { connect } from 'react-redux';
import {
  titleFont,
  respondTo,
  colors,
  Divider,
  bodyFont,
  colorTheme,
  Button,
} from '@123-front/ui-kit';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { layout, container } from '../../main/styles/layout';
import { analyticsPushEvent, analyticsPushPage } from '../../analytics/store/analytics.actions';
import { useQuotationTranslation } from '../../../utils/useQuotationTranslation';
import { useContactConfig } from '../../../utils/useContactConfig';
import { useCarYearLimit } from '../../../utils/useCarYearConfig';
import { useStepLabel } from '../../../utils/useStepLabel';
import { useBaseUrl } from '../../../utils/useBaseUrl';
import ContinueWhatsapp from '../../main/components/ContinueWhatsapp';
import { changeFlux } from '../../wizard/store/wizard.actions';

const maxWidth = 360;
const styles = {
  ...layout,
  ...container(425),
  [respondTo('xs', 'sm')]: {
    marginTop: 24,
  },
  '.logo-container': {
    textAlign: 'center',
    '.invalid-year-img': {
      marginTop: 30,
      marginBottom: 30,
      height: 80,
    },
  },
  '.invalid-year-description': {
    ...bodyFont('md', 'serif'),
    color: colorTheme.textMute,
    textAlign: 'center',
    marginBottom: 40,
  },
  '.invalid-year-title': {
    ...titleFont('md', 'serif'),
    textAlign: 'center',
    marginBottom: 24,
  },
  '.contact-container': {
    background: colors.green.lightest,
    borderRadius: 4,
    padding: '24px 16px',
    p: {
      ...bodyFont('md', 'serif'),
    },
    '.whatsapp-contact': {
      textAlign: 'center',
      p: {
        margin: 4,
      },
    },
    '.contact-link': {
      marginTop: 32,
      textAlign: 'center',
    },
    '.contact-link-only': {
      marginTop: 16,
      textAlign: 'center',
    },
  },
  '.partner-flux-container': {
    '.partner-subtitle': {
      marginBottom: 24,
    },
    ...bodyFont('md'),
    ...container(maxWidth),
    marginBottom: 32,
    color: colorTheme.textMute,
    textAlign: 'center',
  },
};

const InvalidYear = ({ analytics, partner, changeFlux, analyticsPushEvent, analyticsPushPage }) => {
  const { t } = useQuotationTranslation();
  const limitYear = useCarYearLimit();
  const title = useStepLabel({
    partnerLabel: t('invalidYear.partner.description'),
    label: t('invalidYear.description', { year: limitYear }),
  });
  const baseUrl = useBaseUrl();
  const isPartnerFlux = partner ? true : false;
  const year = new Date().getFullYear() - limitYear;
  const { whatsappConfig, phoneConfig } = useContactConfig();

  useEffect(() => {
    analyticsPushEvent(
      'trackEvent',
      'no-search-results-vehiculo-20years',
      'cotizacion',
      analytics.pageAdditionalData,
      'cotizador',
    );
    analyticsPushPage('car', 'invalidYear');
  }, [analyticsPushEvent, analytics.pageAdditionalData, analyticsPushPage]);

  const goToFirstStep = () => {
    changeFlux(baseUrl, '0');
  };

  return (
    <section css={styles}>
      <div>
        <div className={'logo-container'}>
          <img
            className="invalid-year-img"
            alt="contactar"
            src="https://branding.123seguro.com/images/img_contactus.svg"
          />
        </div>
        <div className={'invalid-year-title'}>{t('invalidYear.title', { year })}</div>
        <p className={'invalid-year-description'}>{title}</p>
        {isPartnerFlux && !partner.showWhatsappOnInvalidYear ? (
          <div className="partner-flux-container">
            <div className="partner-subtitle">{t('invalidYear.partner.subtitle')}</div>
            <Button
              type="submit"
              className="go-back-button"
              id="invalid-year-go-back-button"
              onClick={goToFirstStep}
            >
              {t('invalidYear.partner.goBack')}
            </Button>
          </div>
        ) : (
          <div className="contact-container">
            <div className={'whatsapp-contact'}>
              <p>{t('invalidYear.whatsapp')}:</p>
              <ContinueWhatsapp mode="link" textButton={whatsappConfig.display} size="lg" />
            </div>
            <Divider />
            <p className={`${!whatsappConfig.allow ? 'contact-link-only' : 'contact-link'}`}>
              {t('invalidYear.toCall')}{' '}
              <a href={`tel:${phoneConfig.number}`}>{phoneConfig.display}</a>
            </p>
          </div>
        )}
      </div>
    </section>
  );
};

InvalidYear.propTypes = {
  analytics: PropTypes.object,
  partner: PropTypes.object,
  analyticsPushEvent: PropTypes.func,
  analyticsPushPage: PropTypes.func,
  changeFlux: PropTypes.func,
};

const mapStateToProps = (state) => ({
  analytics: state.analytics,
  partner: state.service.partnerConfig.data,
});

export default connect(mapStateToProps, { analyticsPushEvent, analyticsPushPage, changeFlux })(
  InvalidYear,
);
