export const getToken = (action) => {
  return new Promise((res, rej) => {
    window.grecaptcha.enterprise.ready(function () {
      window.grecaptcha.enterprise
        .execute('6LeplSAdAAAAADL9zcdo9vDn0hw3baYA_vV2_LV4', { action })
        .then(function (token) {
          res(token);
        })
        .catch((e) => {
          rej(e);
        });
    });
  });
};
