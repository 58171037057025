import { useSelector } from 'react-redux';
import { useQuotationTranslation } from './useQuotationTranslation';

/**
 * useWhatsappConfig
 * @description Return whatsapp config
 * @param {string} fluxIndex
 * @returns {BaseContactConfig} Whatsapp contact config object
 */
export const useWhatsappConfig = () => {
  const { t } = useQuotationTranslation();
  const traductionWhatsappNumber = t('page403.whatsappNumber.number');
  const traductionWhatsappDisplay = t('page403.whatsappNumber.display');
  const partnerConfig = useSelector((state) => state.service.partnerConfig.data);
  const whatsappNumber = useSelector((state) => state.main.context.whatsappNumber);
  const allowForPartner = partnerConfig && partnerConfig.contactConfig.whatsapp.allow;

  return {
    allow: !partnerConfig ? true : allowForPartner,
    number:
      allowForPartner && partnerConfig.contactConfig.whatsapp.number
        ? partnerConfig.contactConfig.whatsapp.number
        : whatsappNumber.number || traductionWhatsappNumber,
    display:
      allowForPartner && partnerConfig.contactConfig.whatsapp.display
        ? partnerConfig.contactConfig.whatsapp.display
        : whatsappNumber.display || traductionWhatsappDisplay,
  };
};
