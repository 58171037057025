export const getAge = (birthday) => {
  const ageDifMs = Date.now() - birthday;
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const getYearsOfDifference = (year) => {
  if (Number.isInteger(year)) {
    return new Date().getFullYear() - year;
  }

  return null;
};

export const formatDate = (date) => {
  const yyyy = date.getFullYear();
  const dd = date.getDate();
  const mm = date.getMonth() + 1;
  let day = `${dd}`;
  let month = `${mm}`;
  if (dd < 10) {
    day = `0${dd}`;
  }
  if (mm < 10) {
    month = `0${mm}`;
  }
  return `${day}/${month}/${yyyy}`;
};
