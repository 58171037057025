import { getEventObject, getPageObject, getRisk } from '../analytics.service';
import { analyticsTransformer } from '../analytics.transformers';

import {
  ANALYTICS_ADD_ADDITIONAL_DATA,
  ANALYTICS_FLUSH_DATA,
  ANALYTICS_PUSH_EVENT,
  ANALYTICS_PUSH_PAGE,
  ANALYTICS_SET_PLAN,
  ANALYTICS_SET_RISK,
} from './analytics.action-types';

export const analyticsFlushData = () => async (dispatch) => {
  dispatch({ type: ANALYTICS_FLUSH_DATA });
};

export const analyticsSetRisk = (product) => async (dispatch) => {
  dispatch({ type: ANALYTICS_SET_RISK, payload: getRisk(product) });
};

export const analyticsSetPlan = (plan) => async (dispatch) => {
  dispatch({ type: ANALYTICS_SET_PLAN, payload: plan });
};

export const analyticsAddAdditionalData = (module, step, stepData, product) => async (dispatch) => {
  const transformedStepData = analyticsTransformer(module, step, stepData, product);
  dispatch({ type: ANALYTICS_ADD_ADDITIONAL_DATA, payload: transformedStepData });
};

export const analyticsPushPage = (product, step) => async (dispatch, getState) => {
  const { analytics } = getState();
  const pageObj = getPageObject(product, step);
  if (pageObj !== null) {
    dispatch({ type: ANALYTICS_PUSH_PAGE });

    const completePageObjet = {
      ...pageObj,
      ...{ additionalData: { ...analytics.pageAdditionalData, ...pageObj.additionalData } },
    };
    window.dataLayer?.push(completePageObjet);
  }
};

/**
 * @param  {string} event Ej: trackError
 * @param  {string } label Ej: not found 404
 * @param  {string} action Ej: error-campo
 * @param  {object} additionalData
 * @param  {string} category Ej: cotizador
 */
export const analyticsPushEvent = (event, label, action, additionalData, category) => async (
  dispatch,
) => {
  dispatch({ type: ANALYTICS_PUSH_EVENT });
  const eventObj = getEventObject(event, label, action, additionalData, category);
  window.dataLayer?.push(eventObj);
};

/**
 * @param  {string} event
 * @param  {object} eventData
 */
export const analyticsPushEventGA4 = (event, eventData) => async (dispatch) => {
  dispatch({ type: ANALYTICS_PUSH_EVENT });
  window.dataLayer?.push({
    event,
    ...eventData,
  });
};
