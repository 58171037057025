/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Loader } from '@123-front/ui-kit';
import { layout } from '../styles/layout';

const styles = {
  ...layout,
};

export const LoadingPage = () => {
  return (
    <div css={styles} data-testid="loading-page-component">
      <Loader className={'loading'} />
    </div>
  );
};
