import { useSelector } from 'react-redux';

/**
 * useCarYearConfig
 * @description Return car's year limit config according risk type
 * @returns {number} year limit
 */
export const useCarYearLimit = () => {
  const riskType = useSelector((state) => state.wizard.riskType);
  const yearlimits = useSelector((state) => state.main.context.year);
  const key = {
    auto: 'carLimit',
    moto: 'motoLimit',
  };
  const limitYear = yearlimits[key[riskType]];
  return limitYear;
};
