import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { domainResolver } from './utils';
import configureStore from './store';

const initialState = {};

const store = configureStore(initialState);
axios.defaults.baseURL = `/${process.env.REACT_APP_PATH}/api/${domainResolver()}/`;

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
