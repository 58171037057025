export const getPlan = (coverageTypes, coverageId) => {
  return coverageTypes.find((coverage) => coverage.id === coverageId).name;
};

export const getCarrier = (plan) => {
  if (plan === null) {
    return '';
  }
  return plan.carrier.displayName;
};
