/** @jsxRuntime classic */
/** @jsx jsx */

import { jsx } from '@emotion/core';

const styles = {
  position: 'fixed',
  top: 0,
  background: 'white',
  width: '100%',
  height: '100%',
  zIndex: 100,
  '.preloader': {
    position: 'absolute',
    width: 160,
    height: 160,
    left: '50%',
    marginLeft: -80,
    top: '50%',
    marginTop: -80,
  },
  '.ring': {
    display: 'block',
    position: 'absolute',
    width: 160,
    height: 160,
    borderStyle: 'solid',
    borderWidth: 4,
    borderRadius: '50%',
    animation: 'rings 2.4s linear infinite',
    transform: 'scale(0)',
  },
  '@keyframes rings': {
    '0%': {
      transform: 'scale(0)',
    },
    '75%': {
      opacity: 1,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 0,
    },
  },
  '.red': {
    borderColor: 'rgba(235, 34, 39, 0.2)',
  },
  '.yellow': {
    borderColor: 'rgba(250, 175, 66, 0.3)',
    animationDelay: '0.8s',
  },
  '.green': {
    borderColor: 'rgba(140, 197, 65, 0.3)',
    animationDelay: '1.6s',
  },
};

const Experiment = () => {
  return (
    <div css={styles}>
      <div className="preloader">
        <span className="ring red"></span>
        <span className="ring yellow"></span>
        <span className="ring green"></span>
      </div>
    </div>
  );
};

export default Experiment;
