import { useSelector } from 'react-redux';
import { usePhoneConfig } from './usePhoneConfig';
import { useWhatsappConfig } from './useWhatsappConfig';

/**
 * useContactConfig
 * @description Return whatsapp and phone config
 * @param {string} fluxIndex
 * @returns {BaseContactConfig} Contact config object
 */
export const useContactConfig = () => {
  const partnerConfig = useSelector((state) => state.service.partnerConfig.data);
  const whatsappConfig = useWhatsappConfig();
  const phoneConfig = usePhoneConfig();
  const showContactCard = whatsappConfig.allow || phoneConfig.allow;
  const allAllowedContact = whatsappConfig.allow && phoneConfig.allow;
  const allowContactLink = !partnerConfig ? true : false;

  return { whatsappConfig, phoneConfig, showContactCard, allAllowedContact, allowContactLink };
};
