import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { colors, Label } from '@123-front/ui-kit';

const styles = {
  '.container-pretitle + .large-container': {
    '.title-section': {
      paddingTop: 0,
    },
  },
  '.container.container-label': {
    textAlign: 'center',
    marginBottom: 25,
  },
  '.phone-subtitle': {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: '1.2em',
  },
  '.phone-number': {
    color: colors.insurance.prudential,
    fontSize: 20,
    fontWeight: 700,
  },
};

export const StepHeader = ({ title, subtitle, pretitle, label, className = '', variant }) => {
  return (
    <div css={styles} className={className} data-testid="step-header-component">
      {pretitle && (
        <div className={'container container-pretitle'}>
          <h2 className={'pretitle'}>{pretitle}</h2>
        </div>
      )}
      <div className={'large-container'}>
        <h1 className={`${subtitle ? 'title-with-subtitle' : 'title'} title-section`}>{title}</h1>
      </div>
      {subtitle && <div className={'container'}>{<h2 className={'subtitle'}>{subtitle}</h2>}</div>}
      {label && (
        <div className={'container container-label'}>
          <Label variant={variant}>{label.toUpperCase()}</Label>
        </div>
      )}
    </div>
  );
};

StepHeader.propTypes = {
  title: PropTypes.string.isRequired,
  pretitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  subtitle: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
};

StepHeader.defaultProps = {
  subtitle: '',
  label: '',
  className: '',
  variant: '',
};
