import { useTranslation } from 'react-i18next';
import { useProduct } from './useProduct';
import { getPartner } from './fluxes';

export const useBaseUrl = (withPartner = true) => {
  const { t } = useTranslation();
  const product = useProduct();
  const partner = getPartner();
  let pre = '';
  let post = '';

  if (partner && withPartner) {
    pre = `/partner/${partner}`;
  } else {
    pre = `/${t(`insurance`)}`;
  }
  post = `/${t(`products.${product}`)}/${t(`quote`)}`;
  return `${pre}${post}`;
};
