import { combineReducers } from 'redux';
import birthdate from './birthdate/store/birthdate.reducer';
import gender from './gender/store/gender.reducer';
import location from './location/store/location.reducer';
import justProvince from './justProvince/store/justProvince.reducer';
import justLocaly from './justLocaly/store/location.reducer';
import name from './name/store/name.reducer';
import justName from './justName/store/justName.reducer';
import socialName from './socialName/store/socialName.reducer';
import legalId from './legalId/store/legalId.reducer';
import personByLegalId from './personByLegalId/store/personByLegalId.reducer';
import mainDriverLegalId from './mainDriverLegalId/store/mainDriverLegalId.reducer';
import maritalStatus from './maritalStatus/store/maritalStatus.reducer';

const personReducer = combineReducers({
  name,
  namecompany: name,
  justName,
  socialName,
  birthdate,
  justLocaly,
  legalId,
  personByLegalId,
  mainDriverLegalId,
  location,
  justProvince,
  gender,
  maritalStatus,
});

export default personReducer;
