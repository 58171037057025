import { domainResolver } from '../../utils';
import { risk } from './analytics.config';

/**
 * TODO: analizar si se depreca esto
 * transformSelectedValues
 * @description Transforms Selected values to string
 * @param  {} selected Selected value in store
 * @param  {string} delimiter Delimiter value for join values
 * @param  {boolean} toLowerCase Flag for transform options to lower case
 * @param  {string} keyName Object key name for join your values
 * @returns {string } displayNames joined by delimiter
 */
export const transformSelectedValues = (
  selected,
  delimiter = '&&',
  toLowerCase = false,
  keyName = 'displayName',
) => {
  const selectedToArray = Object.values(selected);
  return selectedToArray
    .map((item) => (toLowerCase ? item[keyName].toLowerCase() : item[keyName]))
    .join(delimiter);
};
/**
 * getPrincipalMotorista
 * @description get value of relaSer enum also principalMotorista client component
 * @param  {} key relaSer enum keyname
 * @returns {number} relaSer enum value by key name
 */
export const getPrincipalMotorista = (key) => {
  const relaSeg = {
    Proprio: 1,
    Filho: 2,
    Conjuge: 3,
    PaiMae: 4,
    MotoristaParticular: 5,
    Outros: 6,
    NaoInformacao: 7,
    Socio: 8,
    Funcionario: 9,
  };
  return relaSeg[key];
};

/**
 * getEventObject
 * @description Gets Events object from store
 * @param  {string} event  Name
 * @param  {string} eLab Event's label
 * @param  {string} [eAct='cotizacion'] Event's action
 * @param  {string} [additionalData=null] Already stored Data
 * @param  {string} [eCat='cotizador'] Event's
 * @returns {object} { event, eCat, eAct, eLab, additionalData }
 */
export const getEventObject = (
  event,
  eLab,
  eAct = 'cotizacion',
  additionalData = null,
  eCat = 'cotizador',
) => {
  const ad = additionalData ? { additionalData } : additionalData;
  return { event, eCat, eAct, eLab, ...ad };
};
/**
 * getRisk
 * @description Get risk by given product
 * @param  {string} product current product to quote
 * @returns {object} {riesgo,riesgo_id}
 */
export const getRisk = (product) => {
  return risk[domainResolver()][product];
};
/**
 * getPageObject
 * @description Get object for analytics trackEvent by given product and step
 * @param  {string} product current product to quote
 * @param  {string} step current step in quote flow
 * @returns {object|null} {event, pageName, additionalData}

 */
export const getPageObject = (product, step) => {
  const country = domainResolver();
  const pathSteps = window.location.pathname.split('/');
  const paramsString = window.location.search.replace('?', '');
  const searchParams = new URLSearchParams(paramsString);
  const referrer = searchParams.get('referer');
  return {
    event: 'page_view',
    page_referrer: referrer ?? document.referrer,
    page_location: window.location.href,
    page_path: window.location.pathname,
    page_title: `${country} | ${product} | ${pathSteps[pathSteps.length - 1]}`,
  };
};

/**
 * getFilteredAdditionalData
 * @description Filter data by keys and add common field
 * @param  {object} aditionalData  Addiotnal Data
 * @param  {Array<string>} keys keys to filter additional data
 * @returns {object} Additional data filtered
 */
export const getFilteredAdditionalData = (aditionalData, keys) => {
  const data = {};
  keys.forEach((key) => {
    if (aditionalData.hasOwnProperty(key)) {
      data[key] = aditionalData[key];
    }
  });
  return data;
};
