import {
  getName,
  getGreet,
  getGender,
  getLeadId,
  getMail,
} from '../../../../../utils/mapper/messageText';
import { getPlan, getCarrier } from '../../../../../utils/mapper/carMessageText';
import { formatDate } from '../../../../../utils/date';

const textByParams = (message) => {
  return `${message.opening}${message.action}${message.data}${message.closing}`;
};

const getLeadIdForWhatsapp = (country, lead) => {
  let lead_id = getLeadId(lead);
  return lead_id;
};

const getDetailText = (t, existTraductionKey, state, partnerKey) => {
  const lead = state.car.lead;
  const plan = state.car.results.selected.plan;
  const coverageTypes = state.car.results.options.coverageTypes;

  if (partnerKey && existTraductionKey(`whatsapp.detail.${partnerKey}`)) {
    const partnerMessage = t(`whatsapp.detail.${partnerKey}`, {
      partner: partnerKey,
      name: getName(lead.selected),
      plan_name: getPlan(coverageTypes, plan.quotePrice.coverage.id),
      lead_id: getLeadId(lead),
      email: getMail(lead.selected),
    });
    return partnerMessage;
  } else {
    let message = {
      opening: getGreet(t, lead.selected),
      action: t(`whatsapp.detail.action`),
      data: ` ${
        partnerKey
          ? t(`whatsapp.detail.partner`, {
              partner: partnerKey,
            })
          : ''
      } ${t(`whatsapp.detail.data`, {
        plan_name: getPlan(coverageTypes, plan.quotePrice.coverage.id),
        carrier_name: getCarrier(plan),
        lead_id: getLeadId(lead),
        email: getMail(lead.selected),
      })}`,
      closing: t(`whatsapp.detail.closing`),
    };
    return textByParams(message);
  }
};

const getNoResultsText = (t, existTraductionKey, state, country, partnerKey) => {
  const lead = state.car.lead;
  let lead_id = getLeadIdForWhatsapp(country, lead);

  if (partnerKey && existTraductionKey(`whatsapp.thanksWithoutResults.${partnerKey}`)) {
    return `${t(`whatsapp.thanksWithoutResults.partner`, {
      partner: partnerKey,
    })}
    ${t(`whatsapp.thanksWithoutResults.${partnerKey}`, {
      lead_id,
    })}`;
  }
  let message = {
    opening: getGreet(t, lead.selected),
    action: t(`whatsapp.thanksWithoutResults.action`),
    data: `${
      partnerKey
        ? t(`whatsapp.thanksWithoutResults.partner`, {
            partner: partnerKey,
          })
        : ''
    } ${t(`whatsapp.thanksWithoutResults.data`, {
      lead_id: getLeadId(lead),
      email: getMail(lead.selected),
    })}`,
    closing: t(`whatsapp.thanksWithoutResults.closing`),
  };
  return textByParams(message);
};

const getThanksText = (t, existTraductionKey, state, country, partnerKey) => {
  const plan = state.car.results.selected ? state.car.results.selected.plan : null;
  const coverageTypes = state.car.results.options ? state.car.results.options.coverageTypes : [];
  const lead = state.car.lead;
  let lead_id = getLeadIdForWhatsapp(country, lead);

  if (partnerKey && existTraductionKey(`whatsapp.thanks.${partnerKey}`)) {
    const lead = state.car.lead;
    return t(`whatsapp.thanks.${partnerKey}`, {
      name: getName(lead.selected),
      plan_name: plan ? getPlan(coverageTypes, plan.quotePrice.coverage.id) : '',
      partner: partnerKey,
      lead_id,
      email: getMail(lead.selected),
    });
  } else {
    let message = {
      opening: getGreet(t, lead.selected),
      action: t(`whatsapp.thanks.action`),
      data: `${
        partnerKey
          ? t(`whatsapp.thanksWithoutResults.partner`, {
              partner: partnerKey,
            })
          : ''
      } ${t(`whatsapp.thanks.data`, {
        plan_name: plan ? getPlan(coverageTypes, plan.quotePrice.coverage.id) : '',
        carrier_name: getCarrier(plan),
        lead_id: lead_id,
        email: getMail(lead.selected),
      })}`,
      closing: t(`whatsapp.thanks.closing`),
    };
    return textByParams(message);
  }
};

const getModelText = (t, existTraductionKey, state, country, partnerKey) => {
  if (partnerKey && existTraductionKey(`whatsapp.model.${partnerKey}`)) {
    const lead = state.car.lead.selected;
    return t(`whatsapp.model.${partnerKey}`, {
      brand: lead.brand ? lead.brand.display : '',
      year: lead.year ? lead.year : '',
      partner: partnerKey,
    });
  }
  return getDefaultText(t, state, country);
};

const getDefaultText = (t, state, country) => {
  const lead = state.car.lead.selected;
  const channel = state.service.partnerConfig?.data?.slug;
  let location = '';
  if (country === 'br') {
    location = lead.cepPernoite ? lead.cepPernoite.cepPernoite : '';
  } else {
    location = lead.location ? lead.location.name : '';
  }

  let birthdate = '';
  if (lead.birthdate && typeof lead.birthdate === 'string') {
    birthdate = new Date(lead.birthdate);
  }

  return `${getGreet(t, lead)}${t('whatsapp.generic.action', {
    soap: lead.assuranceType ? lead.assuranceType : '',
    soat: lead.assuranceType ? lead.assuranceType : '',
  })}${t('whatsapp.generic.data', {
    brand: t('whatsapp.generic.texts.brand', { brand: lead.brand ? lead.brand.display : '' }),
    model: t('whatsapp.generic.texts.model', { model: lead.model ? lead.model.name : '' }),
    version: t('whatsapp.generic.texts.version', {
      version: lead.version ? lead.version.name : '',
    }),
    year: t('whatsapp.generic.texts.year', { year: lead.year ? lead.year : '' }),
    isOkm: t('whatsapp.generic.texts.is0km', {
      is0km:
        lead.zerokm === true
          ? t('whatsapp.generic.texts.positive')
          : lead.zerokm === false
          ? t('whatsapp.generic.texts.negative')
          : '',
    }),
    insuranceCarrier: t('whatsapp.generic.texts.insuranceCarrier', {
      insuranceCarrier: lead.hasInsuranceCarrier ? lead.selectedInsuranceCarrier.display : '',
    }),
    bonus: t('whatsapp.generic.texts.bonus', {
      bonus: lead.hasInsuranceCarrier ? lead.bonusType : '',
    }),
    plate: t('whatsapp.generic.texts.plate', { plate: lead.plate ? lead.plate : '' }),
    location: t('whatsapp.generic.texts.location', { location: location }),
    sleepInGarage: t('whatsapp.generic.texts.sleepInGarage', {
      sleepInGarage:
        lead.garagemPernoite.localPernoite === 'RuaOuLocalDesprotegido'
          ? t('whatsapp.generic.texts.negative')
          : lead.garagemPernoite.localPernoite
          ? t('whatsapp.generic.texts.positive')
          : '',
    }),
    driverAgeUnder25: t('whatsapp.generic.texts.driverAgeUnder25', {
      driverAgeUnder25:
        lead.jovemMotorista === 'Nao'
          ? t('whatsapp.generic.texts.negative')
          : lead.jovemMotorista === 'Sim'
          ? t('whatsapp.generic.texts.positive')
          : '',
    }),
    birthdate: t('whatsapp.generic.texts.birthdate', {
      birthdate: birthdate ? formatDate(birthdate) : '',
    }),
    gender: t('whatsapp.generic.texts.gender', {
      gender: lead.gender ? getGender(t, lead.gender) : '',
    }),
    document: lead.legalId
      ? t('whatsapp.generic.texts.document', {
          document: `${lead.legalId.type.description} ${lead.legalId.number}`,
        })
      : '',
    email:
      lead.email && lead.email.length > 0
        ? t('whatsapp.generic.texts.email', {
            email: lead.email,
          })
        : '',
    partner: channel
      ? t('whatsapp.generic.texts.partner', {
          partner: channel,
        })
      : '',
  })}${t('whatsapp.generic.closing')}`;
};

export const getCarText = ({ t, existTraductionKey, state, country, step, partnerKey }) => {
  switch (step) {
    case 'detail':
      return getDetailText(t, existTraductionKey, state, partnerKey);
    case 'noresults':
      return getNoResultsText(t, existTraductionKey, state, country, partnerKey);
    case 'results':
      return getNoResultsText(t, existTraductionKey, state, country, partnerKey);
    case 'thanks':
      return getThanksText(t, existTraductionKey, state, country, partnerKey);
    case 'model':
      return getModelText(t, existTraductionKey, state, country, partnerKey);
    default:
      return getDefaultText(t, state, country);
  }
};
