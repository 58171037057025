import { colors, respondTo } from '@123-front/ui-kit';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useQuotationTranslation } from '../../../utils/useQuotationTranslation';

const style = {
  display: 'flex',
  padding: '6px 16px',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: `${colors.gray.lighten} 2px 2px 3px;`,
  position: 'relative',
  zIndex: 2,
  height: 48,
  '.logo': {
    width: 37,
    height: 32,
    objectFit: 'contain',
  },
  [respondTo('sm')]: {
    height: 59,
  },
};

const MainMenuHeader = () => {
  const { t } = useQuotationTranslation();
  return (
    <div css={style} data-testid="main-menu-header-component">
      <a href={t('brandingConfig.homepageLink')} title="Home">
        <img
          className="logo"
          src={t('brandingConfig.menuHeaderLogo')}
          alt="123Seguro"
          title="123Seguro"
          width="37px"
          height="32px"
        />
      </a>
    </div>
  );
};

MainMenuHeader.defaultProps = {};

export default MainMenuHeader;
