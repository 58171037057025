import Axios from 'axios';
import { analyticsPushEvent } from '../../analytics/store/analytics.actions';
import { getToken } from '../../../utils/recaptcha';
import { getPartner } from '../../../utils/fluxes';
import * as actions from './action-types';
import history from './history';

export const loadContext = (product) => async (dispatch) => {
  dispatch({
    type: actions.CONTEXT_LOAD,
  });
  if (!product) {
    dispatch({
      type: actions.CONTEXT_LOAD_FAIL,
    });
    return;
  }
  const partner = getPartner();
  try {
    const reCaptchaToken = await getToken(actions.CONTEXT_LOAD);

    const res = await Axios.get(`${product}/context`, {
      headers: {
        'x-recaptcha-token': reCaptchaToken,
        'x-recaptcha-action': actions.CONTEXT_LOAD,
        'x-partner': partner,
      },
    });
    dispatch({
      type: actions.CONTEXT_LOAD_SUCCESS,
      payload: res.data,
    });
    dispatch({
      type: actions.PROPERTIES_LOAD_SUCCESS,
      payload: res.data.properties,
    });
  } catch (err) {
    dispatch({
      type: actions.CONTEXT_LOAD_FAIL,
    });
    dispatch(handleServerError(err.response.status));
    console.error(err);
  }
};

export const saveMkt = (mkt) => async (dispatch) => {
  dispatch({
    type: actions.MKT_SAVE,
    payload: mkt,
  });
};

export const saveAudit = (payload) => async (dispatch) => {
  dispatch({
    type: actions.AUDIT_SAVE,
    payload,
  });
};
/**
 * handleServerError
 * @description handles server errors by status code
 * @param  {number} statusCode
 * @returns  {func} async(dispatch)
 */
export const handleServerError = (statusCode) => async (dispatch, getState) => {
  const { main } = getState();

  if (statusCode === 403) {
    history.push(`${main.baseUrl}/suspicious-behavior${window.location.search}`);
  } else {
    dispatch(
      analyticsPushEvent('trackError', `server error - ${window.location.href}`, 'error-campo', {
        errorCode: 500,
      }),
    );
    history.push(`${main.baseUrl}/error${window.location.search}`);
  }
};

export const saveBaseUrl = (baseUrl) => async (dispatch) => {
  dispatch({
    type: actions.BASEURL_SAVE,
    payload: baseUrl,
  });
};
