import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, createStore, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import initialReducer from './initial-reducer';

const middleware = [thunk];

function createReducer(asyncReducers) {
  return combineReducers({
    ...initialReducer,
    ...asyncReducers,
  });
}

let store;

export default function configureStore(initialState) {
  store = createStore(
    createReducer(),
    initialState,
    composeWithDevTools(applyMiddleware(...middleware)),
  );
  store.asyncReducers = {};

  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer;
    store.replaceReducer(createReducer(store.asyncReducers));
  };

  return store;
}

export function getStore() {
  return store;
}
