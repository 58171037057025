export const BRANDS_LOAD = 'BRANDS_LOAD';
export const BRANDS_LOAD_SUCCESS = 'BRANDS_LOADED_SUCCESS';
export const BRANDS_LOAD_FAIL = 'BRANDS_LOAD_FAIL';
export const BRAND_RE_SELECTED = 'BRAND_RE_SELECTED';
export const BRAND_SEARCH_NOT_FOUND = 'BRAND_SEARCH_NOT_FOUND';
export const MODELS_LOAD = 'MODELS_LOAD';
export const MODELS_LOAD_SUCCESS = 'MODELS_LOAD_SUCCESS';
export const MODELS_LOAD_FAIL = 'MODELS_LOAD_FAIL';
export const YEARS_LOAD = 'YEARS_LOAD';
export const YEARS_LOAD_SUCCESS = 'YEAR_LOAD_SUCCESS';
export const YEARS_LOAD_FAIL = 'YEARS_LOAD_FAIL';
export const VERSIONS_LOAD = 'VERSIONS_LOAD';
export const VERSIONS_LOAD_SUCCESS = 'VERSIONS_LOAD_SUCCESS';
export const VERSIONS_LOAD_FAIL = 'VERSIONS_LOAD_FAIL';
export const CATEGORIES_LOAD = 'CATEGORIES_LOAD';
export const CATEGORIES_LOAD_SUCCESS = 'CATEGORIES_LOADED_SUCCESS';
export const CATEGORIES_LOAD_FAIL = 'CATEGORIES_LOAD_FAIL';
export const MARKET_SECTOR_LOAD = 'MARKET_SECTOR_LOAD';
export const MARKET_SECTOR_LOAD_SUCCESS = 'MARKET_SECTOR_LOADED_SUCCESS';
export const MARKET_SECTOR_LOAD_FAIL = 'MARKET_SECTOR_LOAD_FAIL';
export const SURFACE_LOAD = 'SURFACE_LOAD';
export const SURFACE_LOAD_SUCCESS = 'SURFACE_LOADED_SUCCESS';
export const SURFACE_LOAD_FAIL = 'SURFACE_LOAD_FAIL';
export const DEPENDENT_LOAD = 'DEPENDENT_LOAD';
export const DEPENDENT_LOAD_SUCCESS = 'DEPENDENT_LOADED_SUCCESS';
export const DEPENDENT_LOAD_FAIL = 'DEPENDENT_LOAD_FAIL';
export const EXPENSES_LOAD = 'EXPENSES_LOAD';
export const EXPENSES_LOAD_SUCCESS = 'EXPENSES_LOADED_SUCCESS';
export const EXPENSES_LOAD_FAIL = 'EXPENSES_LOAD_FAIL';
export const JUST_LOCALITIES_LOAD = 'JUST_LOCALITIES_LOAD';
export const JUST_LOCALITIES_LOAD_SUCCESS = 'JUST_LOCALITIES_LOAD_SUCCESS';
export const JUST_LOCALITIES_LOAD_FAIL = 'JUST_LOCALITIES_LOAD_FAIL';
export const PROVINCES_LOAD = 'PROVINCES_LOAD';
export const PROVINCES_LOAD_SUCCESS = 'PROVINCES_LOAD_SUCCESS';
export const PROVINCES_LOAD_FAIL = 'PROVINCES_LOAD_FAIL';
export const PARTNER_CONFIG_LOAD = 'PARTNER_CONFIG_LOAD';
export const PARTNER_CONFIG_LOAD_SUCCESS = 'PARTNER_CONFIG_LOAD_SUCCESS';
export const PARTNER_CONFIG_LOAD_FAIL = 'PARTNER_CONFIG_LOAD_FAIL';
