import { useSelector } from 'react-redux';
import { useQuotationTranslation } from './useQuotationTranslation';

/**
 * usePhoneConfig
 * @description Return phone config
 * @param {string} fluxIndex
 * @returns {BaseContactConfig} Phone contact config object
 */
export const usePhoneConfig = () => {
  const { t } = useQuotationTranslation();
  const traductionPhoneNumber = t('page403.customerSupport.number');
  const traductionPhoneDisplay = t('page403.customerSupport.display');
  const partnerConfig = useSelector((state) => state.service.partnerConfig.data);
  const customerSupport = useSelector((state) => state.main.context.customerSupport);
  const allowForPartner = partnerConfig && partnerConfig.contactConfig.phone.allow;

  return {
    allow: !partnerConfig ? true : allowForPartner,
    number:
      allowForPartner && partnerConfig.contactConfig.phone.number
        ? partnerConfig.contactConfig.phone.number
        : customerSupport.number || traductionPhoneNumber,
    display:
      allowForPartner && partnerConfig.contactConfig.phone.display
        ? partnerConfig.contactConfig.phone.display
        : customerSupport.display || traductionPhoneDisplay,
  };
};
