import PropTypes from 'prop-types';

export const versionPropTypes = PropTypes.exact({
  id: PropTypes.string,
  name: PropTypes.string,
});

export const versionReducerPropTypes = PropTypes.exact({
  versions: PropTypes.arrayOf(versionPropTypes),
  selected: versionPropTypes,
  loading: PropTypes.bool,
});
