export const GET_STEPS = 'GET_STEPS';
export const GET_STEPS_SUCCESS = 'GET_STEPS_SUCCESS';
export const GET_STEPS_FAIL = 'GET_STEPS_FAIL';
export const NEXT_STEP = 'NEXT_STEP';
export const RESET_PROGRESS = 'RESET_PROGRESS';
export const REDIRECT_TO_FALLBACK = 'REDIRECT_TO_FALLBACK';
export const SET_RISK_TYPE = 'SET_RISK_TYPE';
export const CHANGE_FLUX = 'CHANGE_FLUX';
export const RESET_STATE = 'RESET_STATE';
export const SAVE_PRODUCT = 'SAVE_PRODUCT';
