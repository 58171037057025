import PropTypes from 'prop-types';

export const wizardReducerPropTypes = PropTypes.exact({
  product: PropTypes.string,
  completedStep: PropTypes.number,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number,
  directionForward: PropTypes.bool,
  steps: PropTypes.arrayOf(PropTypes.object),
  riskType: PropTypes.string,
  fluxes: PropTypes.array,
  activeFlux: PropTypes.string,
  entryFlux: PropTypes.string,
  hasChangeFlux: PropTypes.bool,
  isSheet: PropTypes.bool,
});
