import PropTypes from 'prop-types';

export const contextReducerPropTypes = PropTypes.shape({
  countryName: PropTypes.string,
  whatsappNumber: PropTypes.shape({
    number: PropTypes.string,
    display: PropTypes.string,
  }),
  customerSupport: PropTypes.shape({
    number: PropTypes.string,
    display: PropTypes.string,
  }).isRequired,
  legalIdTypes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ),
  oneTrustId: PropTypes.string,
});

export const mktReducerPropTypes = PropTypes.exact({
  gclid: PropTypes.string,
  landingUrl: PropTypes.string,
  medium: PropTypes.string,
  referer: PropTypes.string,
  source: PropTypes.string,
  queryParams: PropTypes.object,
});

export const mainReducerPropTypes = PropTypes.exact({
  context: contextReducerPropTypes,
  didContextLoad: PropTypes.bool,
  mkt: mktReducerPropTypes,
  baseUrl: PropTypes.string,
  properties: PropTypes.object,
  audit: PropTypes.object,
});
