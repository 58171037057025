import { alpha2List } from './country-alpha2';

const defaultLenguage = alpha2List.AR;
const validLenguales = [...Object.values(alpha2List)];

/**
 * Resolve the current domain (also works on server side rendering)
 * @returns string
 */
export const domainResolver = () => {
  if (!window) {
    return defaultLenguage;
  }
  const hostname = window.location.hostname.split('.');

  const domain = validLenguales.find((alpha) =>
    hostname.some((el) => {
      let urlEl;
      if (el === 'dev-br' || el === 'test-br' || el === 'uat-br') {
        urlEl = 'br';
      } else {
        urlEl = el;
      }
      return urlEl === alpha;
    }),
  );

  if (!validLenguales.includes(domain)) {
    return defaultLenguage;
  }
  return domain;
};
