export const CONTEXT_LOAD = 'CONTEXT_LOAD';
export const CONTEXT_LOAD_SUCCESS = 'CONTEXT_LOADED_SUCCESS';
export const CONTEXT_LOAD_FAIL = 'CONTEXT_LOAD_FAIL';
export const PROPERTIES_LOAD = 'PROPERTIES_LOAD';
export const PROPERTIES_LOAD_SUCCESS = 'PROPERTIES_LOADED_SUCCESS';
export const PROPERTIES_LOAD_FAIL = 'PROPERTIES_LOAD_FAIL';
export const MKT_SAVE = 'MKT_SAVE';
export const AUDIT_SAVE = 'AUDIT_SAVE';
export const PARTNER_CONFIG_LOAD_SUCCESS = 'PARTNER_CONFIG_LOAD_SUCCESS';
export const BASEURL_SAVE = 'BASEURL_SAVE';
