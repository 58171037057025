/* eslint-disable react/prop-types */

/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Loader } from '@123-front/ui-kit';
import actionsService from './store/service.actions';
import config from './store/config';

const Service = ({
  handleNextStep,
  handleChangeFlux,
  handleGetFluxIndex,
  onScrollView,
  componentName,
  ComponentToRender,
  loading,
  isSheet,
  ...actions
}) => {
  const [search, setSearch] = useState(true);
  const [sheetLoading, setSheetLoading] = useState(true);

  const [prevComponentName, setPrevComponentName] = useState(componentName);

  useEffect(() => {
    if (componentName !== prevComponentName) {
      setSearch(true);
      setPrevComponentName(componentName);
    }
  }, [componentName, prevComponentName, search]);

  useEffect(() => {
    const configToStep = config[componentName];
    if (!componentName || !configToStep || loading) {
      setSheetLoading(false);
      return;
    }
    if (search) {
      actions[configToStep.action]();
      setSheetLoading(false);
      setSearch(false);
    }
  }, [componentName, actions, loading, search, isSheet]);

  if (!componentName || (isSheet && sheetLoading) || (!isSheet && loading)) {
    return <Loader className={'loading'} />;
  }
  return (
    <ComponentToRender
      onScrollView={onScrollView}
      onNextStep={handleNextStep}
      onChangeFlux={handleChangeFlux}
      onGetFluxIndex={handleGetFluxIndex}
    />
  );
};

const mapStateToProps = (state) => ({
  loading: state.service.loading,
  isSheet: state.wizard.isSheet,
});

export default connect(mapStateToProps, { ...actionsService })(Service);
