import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useProduct } from './useProduct';
import { getNestedValue } from './getNestedValue';

export const useQuotationTranslation = (route) => {
  const product = useProduct();
  const translation = useTranslation();

  const translationOverrides = useSelector((state) => ({
    override: state.service?.partnerConfig?.data?.translationOverrides,
  }))?.override;

  const t = (key, params) => {
    const override = getNestedValue(translationOverrides, `${product}.${key}`);
    return override ?? translation.t(`${product}.${route ? `${route}.` : ''}${key}`, params);
  };

  const exists = (key) => {
    const getOverride = () => getNestedValue(translationOverrides, `${product}.${key}`);
    return translation.i18n.exists(`${product}.${key}`) || getOverride();
  };

  return { ...translation, t, exists };
};
