export const getName = (lead) => {
  if (!lead.name) {
    return '';
  }
  const { name, surname } = lead.name;

  if (!name && !surname) {
    return null;
  }

  if (surname) {
    return `${name} ${surname}`;
  }

  return `${name}`;
};

export const getGreet = (t, lead) => {
  const name = getName(lead);
  const greetWithName = `${t(`whatsapp.greet.named`)} ${name}.`;
  return `${name ? greetWithName : t(`whatsapp.greet.unnamed`)}\n`;
};

export const getMail = (lead) => {
  return lead.email;
};

export const getLeadId = (lead) => {
  return lead && lead.leadId ? lead.leadId : '';
};

export const getGender = (t, lead) => {
  return lead && lead.gender
    ? lead.gender === 'MALE'
      ? t('whatsapp.generic.texts.male')
      : t('whatsapp.generic.texts.female')
    : '';
};
