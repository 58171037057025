/** @jsxRuntime classic */
/** @jsx jsx */
import { baseStyles, normalize } from '@123-front/ui-kit';
import { Global, jsx } from '@emotion/core';
import { Suspense } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import AppContainer from './modules/main/components/appContainer';
import MainRouter from './modules/main/main.router';
import './modules/main/services/axiosInterceptor';
import history from './modules/main/store/history';
import { nprogressStyles } from './modules/main/styles/nprogress';
import SEO from './shared/detail/components/SEO';
import { useSEOConfig } from './utils/useSEOConfig';

const styles = {
  'html, body': {
    height: '100%',
    minHeight: '100%',
  },
};

const App = () => {
  const seoConfig = useSEOConfig();

  return (
    <section data-testid="app-component">
      {seoConfig && (
        <SEO
          title={seoConfig.title}
          description={seoConfig.description}
          image={seoConfig.image}
          favicons={seoConfig.favicons}
          manifest={seoConfig.manifest}
        />
      )}
      <Global styles={[normalize, baseStyles, nprogressStyles, styles]} />
      <Router history={history}>
        <Suspense fallback={null}>
          <AppContainer>
            <Switch>
              <Route component={MainRouter} />
            </Switch>
          </AppContainer>
        </Suspense>
      </Router>
    </section>
  );
};

export default App;
