import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, respondTo } from '@123-front/ui-kit';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { layout } from '../styles/layout';
import { analyticsPushEvent } from '../../analytics/store/analytics.actions';
import { useQuotationTranslation } from '../../../utils/useQuotationTranslation';
import { useContactConfig } from '../../../utils/useContactConfig';

const styles = {
  ...layout,
  paddingTop: 60,

  '.title': {
    ...layout['.title'],
    marginBottom: 32,
  },
  '.img-container': {
    textAlign: 'center',
    marginBottom: 32,

    img: {
      width: 300,
      [respondTo('xs', 'sm')]: {
        width: 200,
      },
    },
  },
  '.buttons': {
    textAlign: 'center',

    '.home-button': {
      paddingLeft: 48,
      paddingRight: 48,
      marginBottom: 24,
    },
  },
  a: {
    fontWeight: 700,
  },
};

const ErrorPage = ({ title, buttonText, onButtonClick, analyticsPushEvent, landingUrl }) => {
  const { t } = useQuotationTranslation();
  const { allowContactLink } = useContactConfig();

  useEffect(() => {
    if (landingUrl) {
      analyticsPushEvent(
        'trackError',
        `TypeError - ${landingUrl}`,
        'error-campo',
        null,
        'cotizador',
      );
    }
  }, [analyticsPushEvent, landingUrl]);

  return (
    <div css={styles} data-testid="error-page-component">
      <div className={'container'}>
        <div className={'img-container'}>
          <img src={'https://branding.123seguro.com/illustration/illus_empty.svg'} alt={'empty'} />
        </div>
      </div>
      <div className={'large-container'}>
        <h1 className={'title'}>{title}</h1>
      </div>
      <div className={'container buttons'}>
        <Button
          className={'home-button'}
          onClick={() => {
            onButtonClick();
            analyticsPushEvent('trackEvent', 'back-from-error', 'cotizacion');
          }}
          data-testid="error-page-button"
        >
          {buttonText}
        </Button>
        {allowContactLink && <a href={t('errorPage.url')}>{t('errorPage.link')}</a>}
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  analyticsPushEvent: PropTypes.func,
  landingUrl: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  landingUrl: state.main.mkt.landingUrl,
});

export default connect(mapStateToProps, { analyticsPushEvent })(ErrorPage);
