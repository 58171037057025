import { getGreet, getMail } from '../../../../../utils/mapper/messageText';

const textByParams = (message) => {
  return `${message.opening}${message.action}${message.data}${message.closing}`;
};

const getNoResultsText = ({ t, business }) => {
  const lead = business.lead;
  let message = {
    opening: getGreet(t, lead.selected),
    action: t(`whatsapp.thanksWithoutResults.action`),
    data: t(`whatsapp.thanksWithoutResults.data`, {
      lead_id: lead.selected.leadId,
      email: getMail(lead.selected),
    }),
    closing: t(`whatsapp.thanksWithoutResults.closing`),
  };
  return textByParams(message);
};

const getThanksText = ({ t, business, partnerConfig }) => {
  if (partnerConfig && partnerConfig.business.showCustomLabel) {
    const lead = business.lead;
    let message = {
      opening: getGreet(t, lead.selected),
      action: t(`whatsapp.thanksWithoutResults.partner`, {
        partner: t(`whatsapp.partners.${partnerConfig.keyName}`),
      }),
      data: t(`whatsapp.thanksWithoutResults.data`, {
        lead_id: lead.selected.leadId,
        email: getMail(lead.selected),
      }),
      closing: t(`whatsapp.thanksWithoutResults.closing`),
    };
    return textByParams(message);
  } else {
    return getNoResultsText({ t, business });
  }
};

const getMarketSectorsText = ({ t, business, partnerConfig }) => {
  if (partnerConfig && partnerConfig.business.showCustomLabel) {
    const lead = business.lead.selected;
    return `
    ${getGreet(t, lead)}
    ${t(`whatsapp.marketSector.partner`, {
      partner: t(`whatsapp.partners.${partnerConfig.keyName}`),
    })}
    ${t('whatsapp.generic.closing')}`;
  } else {
    return getDefaultText({ t, business });
  }
};

const getDefaultText = ({ t, business }) => {
  const lead = business.lead.selected;
  let readableExpectations = lead.expectations
    .map((expectation) => {
      return `${expectation.displayName} `;
    })
    .join(' - ');
  return `${getGreet(t, lead)}${t('whatsapp.generic.action')}${t('whatsapp.generic.data', {
    marketSector: t('whatsapp.generic.texts.marketSector', {
      marketSector: lead.marketSectors ? lead.marketSectors.display : '',
    }),
    surface: t('whatsapp.generic.texts.surface', {
      surface: lead.surface ? lead.surface.baseValue : '',
    }),
    location: t('whatsapp.generic.texts.location', {
      location: lead.location ? lead.location.provinceName : '',
    }),
    expectation: t('whatsapp.generic.texts.expectation', { expectation: readableExpectations }),
    email: t('whatsapp.generic.texts.email', { email: lead.email ? lead.email : '' }),
  })}${t('whatsapp.generic.closing')}`;
};

export const getBusinessText = ({ t, step, state }) => {
  const partnerConfig = state?.service?.partnerConfig?.data;
  const business = state.business;
  switch (step) {
    case 'noresults':
      return getNoResultsText({ t, business });
    case 'marketSectors':
      return getMarketSectorsText({ t, business, partnerConfig });
    case 'thanks':
      return getThanksText({ t, business, partnerConfig });
    default:
      return getDefaultText({ t, business });
  }
};
