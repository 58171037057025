import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useHistory } from 'react-router-dom';
import { useQuotationTranslation } from '../../../utils/useQuotationTranslation';
import ErrorPage from './ErrorPage';

export const Page500 = () => {
  const history = useHistory();
  const { t } = useQuotationTranslation();
  const goBack = () => history.go(-1);

  return (
    <ErrorPage
      title={t('internalError.title')}
      buttonText={t('internalError.button')}
      onButtonClick={goBack}
    />
  );
};

Page500.propTypes = {
  className: PropTypes.string,
};
