import { RESET_STATE } from '../../../wizard/store/wizard.action-types';
import { NAME_SAVE } from './name.action-types';

const INITIAL_STATE = {
  selected: {
    name: null,
    surname: null,
    isCompany: false,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        selected: {
          name: null,
          surname: null,
          isCompany: false,
        },
      };
    case NAME_SAVE:
      return {
        ...state,
        selected: action.payload,
      };
    default:
      return state;
  }
};
