import { useTranslation } from 'react-i18next';
import i123n from '../i18n';
import { domainResolver } from './domain-resolver';
import { getPartner } from './fluxes';
import { getKeyByValue } from './object';

const REACT_APP_DEMO_TO_PRODUCT = process.env.REACT_APP_DEMO_TO_PRODUCT;

export const useProduct = () => {
  useTranslation('translation');
  let productPosition = 2;
  if (getPartner()) {
    productPosition += 1;
  }
  const productName = window.location.pathname.split('/')[productPosition];
  if (productName && productName === REACT_APP_DEMO_TO_PRODUCT) {
    return 'demo';
  }
  const { products } = i123n.getDataByLanguage(domainResolver()).translation;
  return productName ? getKeyByValue(products, productName) : null;
};

/**
 * getProductEndpoint
 * @description Return used product for endpoint
 * @param {string} product
 * @returns {string} product for endpoint
 */
export const getProductEndpoint = (product) => {
  switch (product) {
    case 'car':
      return 'cars';
    case 'business':
      return 'business';
    case 'telecare':
      return 'telecare';
    case 'life':
      return 'life';
    case 'personalAccidents':
      return 'personal-accidents';
    default:
      return '';
  }
};
