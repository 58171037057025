import { useSelector } from 'react-redux';

/**
 * useStepLabel
 * @description Return label
 * @param {string} partnerLabel
 * @param {string} label
 * @returns {string} label
 */
export const useStepLabel = ({ partnerLabel, label }) => {
  const partnerConfig = useSelector((state) => state.service.partnerConfig.data);
  return partnerConfig?.hasCustomLabels && partnerLabel ? partnerLabel : label;
};
