export const getCarrier = (life) => {
  const { companyPlans } = life.results;
  return companyPlans.company;
};

export const getPlan = (life) => {
  const { companyPlans, selected } = life.results;
  const plan = companyPlans.plans.find((plan) => plan.id === selected.plan);
  return plan.name;
};
