/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { getFingerprint, getFingerprintData } from '@thumbmarkjs/thumbmarkjs';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import CookieConsenter from '../../../modules/main/components/CookieConsenter';
import { domainResolver } from '../../../utils';
import { getPartner } from '../../../utils/fluxes';
import { useBaseUrl } from '../../../utils/useBaseUrl';
import { useProduct } from '../../../utils/useProduct';
import { mainReducerPropTypes } from '../main.proptypes';
import { getMktObject } from '../services/mkt.service';
import { loadContext, saveAudit, saveBaseUrl, saveMkt } from '../store/actions';
import AppContainerContent from './appContainerContent';

const styles = {
  minHeight: '100vh',
  position: 'relative',
};

const AppContainer = ({ children, mainState, saveBaseUrl, loadContext, saveMkt, saveAudit }) => {
  const baseUrl = useBaseUrl();

  useEffect(() => {
    saveBaseUrl(baseUrl);
  }, [baseUrl, saveBaseUrl]);

  const {
    context: { oneTrustId },
    didContextLoad,
  } = mainState;
  const pathName = useLocation().pathname;
  const currentUrl = pathName.split('/');
  const currentPage = currentUrl[currentUrl.length - 1];
  const isExperiment = currentPage === 'experiment';

  useEffect(() => {
    if (didContextLoad) {
      // el create element es para evitar que rompa el test ya que no puede ver los elementos del index html
      const preloader = document.getElementById('preloader') || document.createElement('div');
      preloader.remove();
    }
  }, [didContextLoad]);

  const product = useProduct();
  useEffect(() => {
    if (!didContextLoad) {
      loadContext(product);
    }
  }, [didContextLoad, product, loadContext]);

  useEffect(() => {
    saveMkt(getMktObject({ partner: getPartner() }));
  }, [saveMkt]);

  const [didCookieConsenterLoad, setDidCookieConsenterLoad] = useState(isExperiment);
  const onCookieConsenterLoad = useCallback(() => {
    setDidCookieConsenterLoad(true);
  }, []);

  useEffect(() => {
    if (didCookieConsenterLoad) {
      const gtmIds = {
        ar: 'GTM-N8SRQZL',
        cl: 'GTM-P2PPCL8',
        co: 'GTM-MH2P868',
        br: 'GTM-MDRH9XT',
        // TODO: GA configuration
        // mx: 'GTM-MDRH9XT',
      };
      TagManager.initialize({ gtmId: gtmIds[domainResolver()] });
    }
  }, [didCookieConsenterLoad]);

  useEffect(() => {
    getFingerprint()
      .then((fp) => {
        saveAudit({ thumbmark: fp });
      })
      .catch((err) => {
        saveAudit({ thumbmark: err.message ?? 'error' });
      });
    getFingerprintData()
      .then((data) => {
        saveAudit({
          languages: data.locales?.languages,
          useragent: data.system?.useragent,
          platform: data.system?.platform,
          product: data.system?.product,
          productSub: data.system?.productSub,
          browserName: data.system?.browser?.name,
          browserVersion: data.system?.browser?.version,
        });
      })
      .catch((err) => {
        saveAudit({
          languages: err.message ?? 'N/A',
          useragent: err.message ?? 'N/A',
          platform: err.message ?? 'N/A',
          product: err.message ?? 'N/A',
          productSub: err.message ?? 'N/A',
          browserName: err.message ?? 'N/A',
          browserVersion: err.message ?? 'N/A',
        });
      });
  }, [saveAudit]);

  return (
    <main data-testid="app-container-component" css={styles}>
      {didContextLoad && !isExperiment && oneTrustId && (
        <CookieConsenter dataDomainScript={oneTrustId} onLoad={onCookieConsenterLoad} />
      )}
      {((didContextLoad && !oneTrustId) || didCookieConsenterLoad) && (
        <AppContainerContent>{children}</AppContainerContent>
      )}
    </main>
  );
};

AppContainer.propTypes = {
  children: PropTypes.element.isRequired,
  mainState: mainReducerPropTypes.isRequired,
  loadContext: PropTypes.func.isRequired,
  saveMkt: PropTypes.func.isRequired,
  saveAudit: PropTypes.func.isRequired,
  saveBaseUrl: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  mainState: state.main,
});

export default connect(mapStateToProps, { saveBaseUrl, loadContext, saveMkt, saveAudit })(
  AppContainer,
);
