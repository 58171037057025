import { RESET_STATE } from '../../../wizard/store/wizard.action-types';
import { SOCIAL_NAME_SAVE } from './socialName.action-types';

const INITIAL_STATE = {
  selected: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_STATE:
      return {
        ...state,
        selected: null,
      };
    case SOCIAL_NAME_SAVE:
      return {
        ...state,
        selected: action.payload,
      };
    default:
      return state;
  }
};
