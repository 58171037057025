import { getGreet, getGender, getMail, getLeadId } from '../../../../../utils/mapper/messageText';
import { getCarrier, getPlan } from '../../../../../utils/mapper/lifeMessageText';
import { formatDate } from '../../../../../utils/date';

const textByParams = (message) => {
  return `${message.opening}${message.action}${message.data}${message.closing}`;
};

const getDetailText = (t, state) => {
  const life = state.life;
  const lead = state.life.lead.selected;

  let message = {
    opening: getGreet(t, lead),
    action: t(`whatsapp.detail.action`),
    data: t(`whatsapp.detail.data`, {
      carrier_name: getCarrier(life),
      plan_name: getPlan(life),
      lead_id: getLeadId(lead),
      email: getMail(lead),
    }),
    closing: t('whatsapp.ending'),
  };
  return textByParams(message);
};

const getThanksText = (t, state) => {
  const life = state.life;
  const lead = state.life.lead.selected;

  let message = {
    opening: getGreet(t, lead),
    action: t(`whatsapp.thanks.action`),
    data: t(`whatsapp.thanks.data`, {
      carrier_name: getCarrier(life),
      plan_name: getPlan(life),
      lead_id: getLeadId(lead),
      email: getMail(lead),
    }),
    closing: t('whatsapp.ending'),
  };
  return textByParams(message);
};

const getDefaultText = (t, state) => {
  const person = state.person;
  const lead = state.life
    ? state.life.lead.selected
    : {
        name: person.name.selected,
        birthdate: person.birthdate.selected,
        gender: person.gender.selected,
        location: person.location.selected,
      };
  return `${getGreet(t, lead)}${t('whatsapp.generic.action')}${t('whatsapp.generic.data', {
    birthdate: t('whatsapp.generic.texts.birthdate', {
      birthdate: lead.birthdate ? formatDate(lead.birthdate) : '',
    }),
    gender: t('whatsapp.generic.texts.gender', {
      gender: getGender(t, lead),
    }),
    location: t('whatsapp.generic.texts.location', {
      location: lead.location ? lead.location.name : '',
    }),
    email: t('whatsapp.generic.texts.email', { email: lead.email ? lead.email : '' }),
  })}${t('whatsapp.generic.closing')}`;
};

export const getLifeText = ({ t, state, step }) => {
  switch (step) {
    case 'detail':
      return getDetailText(t, state);
    case 'thanks':
      return getThanksText(t, state);
    default:
      return getDefaultText(t, state);
  }
};
