/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Button, respondTo, boxShadowed, bodyFont, titleFont, colorTheme } from '@123-front/ui-kit';
import { layout } from '../styles/layout';
import { useQuotationTranslation } from '../../../utils/useQuotationTranslation';
import { useBaseUrl } from '../../../utils/useBaseUrl';
import { getPartner } from '../../../utils/fluxes';
import { loadPartner } from '../../service/store/service.actions';
import { useProduct } from '../../../utils/useProduct';
import { saveProduct } from '../../wizard/store/wizard.actions';

const styles = {
  ...layout,
  padding: '60px 16px',

  '.title': {
    ...layout['.title'],
    marginBottom: 60,
  },
  '.description': { ...layout['.subtitle'], marginBottom: 60 },
  '.img-container': {
    textAlign: 'center',
    marginBottom: 32,

    img: {
      width: 300,
      [respondTo('xs', 'sm')]: {
        width: 200,
      },
    },
  },
  '.contact-info': {
    maxWidth: 360,
    margin: 'auto',
    textAlign: 'center',

    '.contact-info-box': [boxShadowed],
    '.ws-img': {
      height: 44,
      width: 44,
      float: 'left',
    },
    '.ws-text-box': {
      margin: '4px 44px 0 44px',
      '.ws-text': { ...bodyFont('md'), marginBottom: 4 },
      '.ws-link': {
        ...titleFont('sm'),
        color: colorTheme.interactive,
        fontSize: '20px !important',
      },
    },
    '.ph-body': {
      ...bodyFont('md'),
      marginBottom: 0,
    },
    [respondTo('xs', 'sm')]: {
      '.ws-img': {
        display: 'none',
      },
      '.ws-text-box': {
        margin: 0,
      },
    },
  },
};

const Page403 = ({ partnerConfig, loadPartner, productSaved, saveProduct }) => {
  const { t } = useQuotationTranslation();
  const defaultFlux = '0';
  const baseUrl = useBaseUrl();

  const partner = getPartner();
  const product = useProduct();

  useEffect(() => {
    if (!productSaved) {
      saveProduct(product);
    }
  }, [productSaved, saveProduct, product]);

  useEffect(() => {
    if (productSaved && partner && !partnerConfig) {
      loadPartner();
    }
  }, [partnerConfig, partner, loadPartner, productSaved]);

  const goToHome = () =>
    (window.location.href = `${baseUrl}/${defaultFlux}/${window.location.search}`);

  return (
    <div css={styles} data-testid="error-page-component">
      <div className={'container'}>
        <div className={'img-container'}>
          <img src={'https://branding.123seguro.com/images/img_unknown_user.svg'} alt={'empty'} />
        </div>
      </div>

      <h1 className={'title'}>{t('page403.title')}</h1>
      <p className="description">
        {t('page403.description')}{' '}
        <Button variant="link" onClick={goToHome}>
          {t('page403.restartText')}
        </Button>
      </p>
    </div>
  );
};

const mapStateToProps = (state) => ({
  partnerConfig: state.service.partnerConfig.data,
  productSaved: state.wizard.product,
});

Page403.propTypes = {
  partnerConfig: PropTypes.object,
  loadPartner: PropTypes.func,
  productSaved: PropTypes.string,
  saveProduct: PropTypes.func,
};

export default connect(mapStateToProps, { loadPartner, saveProduct })(Page403);
