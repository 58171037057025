/** @jsxRuntime classic */
/** @jsx jsx */
import { bodyFont, colorTheme } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';

const styles = {
  zIndex: 1,
  position: 'sticky',
  top: '100%',
  width: '100%',
  padding: 16,
  textAlign: 'center',
  a: {
    padding: '0 5px 0 5px',
  },
  '.terms-content': {
    textAlign: 'center',
    ...bodyFont('xs'),
    color: colorTheme.textLight,
  },
};

const QuotationFooter = () => {
  return (
    <footer css={styles}>
      <div className="terms-content">
        This site is protected by reCAPTCHA Enterprise and the <br />
        <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
          Google Privacy Policy
        </a>
        and
        <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>
        apply.
      </div>
    </footer>
  );
};

export default QuotationFooter;
