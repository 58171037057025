import Axios from 'axios';
import { getAge } from '../../../utils/date';
import {
  defaultFlux,
  detectActiveFlux,
  getPartner,
  getStepsConfig,
  getIsSheetFlux,
} from '../../../utils/fluxes';
import {
  analyticsAddAdditionalData,
  analyticsFlushData,
  analyticsSetRisk,
} from '../../analytics/store/analytics.actions';
import history from '../../main/store/history';
import i123n from '../../../i18n';
import { domainResolver } from '../../../utils/domain-resolver';
import { alpha2List } from '../../../utils/country-alpha2';
import { loadFluxesInformation } from '../../../utils/fluxManagementBr';
import * as actions from './wizard.action-types';
import { getModuleName, getStepsList } from './wizard.store.utils';

export const getProductStepsConfig = (product) => async (dispatch) => {
  dispatch({
    type: actions.GET_STEPS,
  });
  try {
    const partner = getPartner();
    const url = partner ? `/wizard/${product}/${partner}` : `/wizard/${product}`;
    const res = await Axios.get(url);
    const fluxes = res.data;
    const activeFlux = detectActiveFlux() || defaultFlux;
    let config = getStepsConfig(fluxes, activeFlux);
    if (config.length === 0) {
      config = getStepsConfig(fluxes, defaultFlux);
    }
    const country = domainResolver();
    if (country === alpha2List.BR) {
      loadFluxesInformation(fluxes);
    }

    dispatch({
      type: actions.GET_STEPS_SUCCESS,
      payload: {
        fluxes,
        activeFlux,
        entryFlux: activeFlux,
        product,
        isSheet: getIsSheetFlux(fluxes, activeFlux),
        steps: config,
        totalSteps: getStepsList(config).length,
      },
    });
    dispatch(analyticsFlushData());
    dispatch(analyticsSetRisk(product));
  } catch (err) {
    dispatch({
      type: actions.GET_STEPS_FAIL,
    });
    console.error(err);
  }
};

const getStepData = (currentModuleName, currentStepName, getState) => {
  let stepData = null;
  if (['person', 'contact', 'checkout'].includes(currentModuleName)) {
    switch (currentStepName) {
      case 'province':
      case 'locality':
        stepData = getState()[currentModuleName]['location']?.selected;
        break;
      default:
        stepData = getState()[currentModuleName][currentStepName]?.selected;
        break;
    }
  } else {
    const leadData = getState()[currentModuleName].lead.selected[currentStepName];
    if (leadData) {
      switch (currentStepName) {
        case 'year':
          const fabricationYear = getState()[currentModuleName].lead.selected.fabrYear;
          stepData = {
            year: leadData,
            fabricationYear,
          };
          break;
        default:
          stepData = leadData;
          break;
      }
    }
  }
  return stepData;
};

export const moveToNextStep = (urlToRedirect) => async (dispatch, getState) => {
  const { wizard } = getState();
  const { product } = wizard;
  const currentStepName = getStepsList(wizard.steps)[wizard.currentStep - 1];
  const currentModuleName = getModuleName(wizard.steps, currentStepName);

  let stepData = getStepData(currentModuleName, currentStepName, getState);

  if (stepData) {
    if (stepData instanceof Date) {
      stepData = getAge(stepData);
    }
    dispatch(
      analyticsAddAdditionalData(
        currentModuleName,
        currentStepName,
        {
          ...(typeof stepData === 'object' ? stepData : { selected: stepData }),
        },
        product,
      ),
    );
  }

  dispatch({
    type: actions.NEXT_STEP,
    payload: { completedStep: wizard.currentStep, currentStep: wizard.currentStep + 1 },
  });
  history.push(urlToRedirect);
};

export const resetProgress = (toStep = 0) => async (dispatch) => {
  dispatch({
    type: actions.RESET_PROGRESS,
    payload: { completedStep: toStep, currentStep: toStep + 1 },
  });
};

export const goToDefaultFallback = () => async (dispatch) => {};

export const setRiskType = (riskType) => async (dispatch) => {
  dispatch({
    type: actions.SET_RISK_TYPE,
    payload: riskType,
  });
};

/**
 * changeFlux
 * @description change the work flux
 * @param {string} baseUrl baseUrl of the flux
 * @param {string} toFlux flux number to change
 * @param {number} toStep number of flux step to change
 * @returns {number} Active flux
 */
export const changeFlux = (baseUrl, toFlux, toStep = 0) => async (dispatch, getState) => {
  const { wizard } = getState();
  let config = getStepsConfig(wizard.fluxes, toFlux);
  if (config.length === 0) {
    config = getStepsConfig(wizard.fluxes, defaultFlux);
  }
  const steps = getStepsList(config);
  const { routeNames } = i123n.getDataByLanguage(domainResolver()).translation[wizard.product];
  const transStepsList = steps.map((step) => routeNames.pages[step]);

  dispatch({
    type: actions.CHANGE_FLUX,
    payload: {
      activeFlux: toFlux || defaultFlux,
      completedStep: toStep,
      currentStep: toStep + 1,
      steps: config,
      totalSteps: steps.length,
    },
  });

  history.push(`${baseUrl}/${toFlux}/${transStepsList[toStep]}${window.location.search}`);
};

/**
 * redirectToPartner
 * @description redirect and restart wizard state
 * @param {string} baseUrl baseUrl of the flux
 * @param {PartnerConfig} partnerConfig flux number to change
 * @returns {void}
 */
export const redirectToPartner = (baseUrl, partnerConfig) => async (dispatch) => {
  window.location.href = `${baseUrl}/partners/${partnerConfig.slug}${window.location.search}`;
};

export const saveProduct = (product) => async (dispatch) => {
  dispatch({
    type: actions.SAVE_PRODUCT,
    payload: product,
  });
};
