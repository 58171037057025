import PropTypes from 'prop-types';

export const modelPropTypes = PropTypes.exact({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  riskType: PropTypes.string,
  codCar: PropTypes.string,
  codFipe: PropTypes.string,
  classe: PropTypes.string,
});

export const modelReducerPropTypes = PropTypes.exact({
  loading: PropTypes.bool,
  savedByMonofield: PropTypes.bool,
  models: PropTypes.arrayOf(modelPropTypes),
  selected: modelPropTypes,
});
