import { getPlanForAnalyticsCl } from '../../utils/mapper/analytics';
import { getRisk, transformSelectedValues, getPrincipalMotorista } from './analytics.service';

const stepsTransform = {
  base: { risk: ({ name, id }) => ({ riesgo: name, riesgo_id: id }) },
  life: {
    dependents: () => ({}),
    expenses: ({ baseValue }) => ({
      title: 'gastos mensuales',
      gastos_mensuales: baseValue,
    }),
    lead: ({ leadId }) => ({ lead_id: leadId }),
    detail: () => ({
      title: 'detalle de seguro',
    }),
    results: ({ plan }) => ({
      title: 'cotizaciones',
      plan,
    }),
    checkout: () => ({}),
    thanks: () => ({
      title: 'Contratación Realizada',
    }),
  },
  lifePrudential: {
    lead: ({ leadId }) => ({ lead_id: leadId }),
    detail: () => ({
      title: 'detalle de seguro',
    }),
    results: ({ plan }) => ({
      title: 'cotizaciones',
      plan,
    }),
    checkout: () => ({}),
    thanks: () => ({
      title: 'Contratación Realizada',
    }),
  },
  business: {
    categories: ({ id, display }) => ({
      categoria: display,
      categoria_id: id,
    }),
    marketSectors: ({ baseValue, display }) => ({
      title: 'rubro',
      rubro: display,
      rubro_id: baseValue,
    }),
    surface: ({ baseValue }) => ({ title: 'superficie', superficie: baseValue }),
    expectations: (selected) => ({
      expectativa: transformSelectedValues(selected),
      expectativa_id: transformSelectedValues(selected, '&&', false, 'value'),
    }),
    lead: ({ leadId }) => ({ lead_id: leadId, tipo_contratacion: 'online' }),
    thanks: () => ({
      title: 'Contratación Realizada',
    }),
  },
  car: {
    searchByPlate: () => ({}),
    carByPlate: ({ brand, model, version, year }) => ({
      marca: brand.brandName,
      marca_id: brand.brandId,
      anio: year,
      modelo: model.modelName,
      modelo_id: model.modelId,
      version: version.versionName,
      version_id: version.versionId,
    }),
    supervielle: () => ({}),
    partner: () => ({}),
    carBaseSheet: () => ({}),
    carUseSheet: () => ({}),
    personalInformationSheet: () => ({}),
    assuranceType: () => ({}),
    monofield: () => ({}),
    brand: ({ name, id }) => ({ marca: name, marca_id: id }),
    year: ({ fabricationYear, year }) => {
      const yearFormat = fabricationYear ? `${fabricationYear}-${year}` : year;
      return { anio: yearFormat };
    },
    model: ({ name, id, riskType }) => {
      const obj = { modelo: name, modelo_id: id };
      if (riskType) {
        obj.riesgo = riskType;
      }
      return obj;
    },
    version: ({ name, id }) => ({ version: name, version_id: id }),
    zerokm: ({ selected }) => ({ '0km': selected }),
    accessories: ({ gnc, satelliteTracking }) => ({
      gnc,
      rastreo: satelliteTracking,
    }),
    plate: () => ({}),
    loadResults: () => ({}),
    gasKit: () => ({}),
    carUse: (selected) => ({
      uso: selected,
      uso_id: selected,
    }),
    cepPernoite: () => ({}),
    principalMotorista: ({ selected }) => ({
      conductor_principal: selected,
      conductor_principal_id: getPrincipalMotorista(selected),
    }),
    jovemMotorista: ({ selected }) => ({ conductor_joven: selected !== 'Nao' }),
    garagemPernoite: ({ garageId }) => ({ garage: garageId !== 'Nao' }),
    residenceType: () => ({}),
    lead: (selected) => ({
      lead_id: selected.leadId,
      tipo_contratacion: 'online',
      title: 'Presentación de Cotizaciones',
    }),
    results: ({ plan }) => {
      let precio = plan.quotePrice.price.price;
      let suma_asegurada = plan.asseguredSum;
      let cobertura = plan.quotePrice.coverage.title;
      let cobertura_id = plan.quotePrice.coverage.id;

      if (plan.deductibleSelected != null) {
        const mappedPlan = getPlanForAnalyticsCl(plan.deductibleSelected);
        precio = mappedPlan.precio;
        cobertura = mappedPlan.cobertura;
        cobertura_id = mappedPlan.cobertura_id;
      }

      return {
        title: 'Presentación de Cotizaciones',
        aseguradora: plan.carrier.keyName,
        aseguradora_id: plan.carrier.id,
        cobertura,
        cobertura_id,
        precio,
        suma_asegurada,
      };
    },
    noresults: () => ({
      title: 'Vehiculo No Asegurable',
    }),
    hire: ({ companyId, company }) => ({
      aseguradora: company,
      aseguradora_id: companyId,
    }),
    thanks: () => ({
      title: 'Contratación Realizada',
    }),
  },
  person: {
    birthdate: ({ selected }) => {
      return {
        edad: selected,
        title: 'edad',
      };
    },
    gender: ({ selected }) => ({
      genero: selected,
      title: 'genero',
    }),
    legalId: () => {},
    mainDriverLegalId: () => {},
    personByLegalId: () => {},
    searchByLegalId: () => {},
    maritalStatus: ({ selected }) => ({
      estado_civil: selected === 'married' ? true : false,
    }),
    name: () => ({
      title: 'nombre del asegurado',
    }),
    justName: () => ({
      title: 'nombre del asegurado',
    }),
    socialName: () => ({
      title: 'nombre social del asegurado (br)',
    }),
    namecompany: () => ({}), // esta data aparentemente no se guarda
    justLocaly: ({ provinceId, provinceName, id, name }) => ({
      departamento_id: provinceId,
      departamento: provinceName,
      barrio: name,
      barrio_id: id,
      title: 'ubicacion',
    }),
    justProvince: ({ provinceId, provinceName }) => ({
      provincia_id: provinceId,
      provincia: provinceName,
    }),
    location: ({ provinceId, provinceName, id, name }) => ({
      departamento_id: provinceId,
      departamento: provinceName,
      barrio: name,
      barrio_id: id,
      title: 'ubicacion',
    }),
    province: ({ provinceId, provinceName }) => ({
      departamento_id: provinceId,
      departamento: provinceName,
      title: 'ubicacion',
    }),
    locality: ({ id, name }) => ({
      barrio: name,
      barrio_id: id,
      title: 'ubicacion',
    }),
  },
  demo: {
    funeralExpensesCoverage: () => {},
    surgicalInterventions: () => {},
    diabetes: () => {},
  },
  contact: { email: () => ({ title: 'email' }), phone: () => ({ title: 'telefono' }) },
  checkout: {
    person: () => ({}),
    hiringPerson: () => ({}),
    contact: () => ({}),
    address: () => ({}),
    car: () => ({}),
  },
};

export const analyticsTransformer = (module, step, stepData, product) => {
  let risk = {};
  if (product !== 'business' && product !== 'car') {
    risk = getRisk(product);
  }

  return {
    ...risk,
    ...stepsTransform[module][step](stepData),
  };
};
