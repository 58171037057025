export const SAVE_PLANS = 'SAVE_PLANS';
export const SAVE_PLANS_SUCCESS = 'SAVE_PLANS_SUCCESS';
export const SAVE_PLANS_FAIL = 'SAVE_PLANS_FAIL';
export const SAVE_OPTIONS = 'SAVE_OPTIONS';
export const SAVE_OPTIONS_SUCCESS = 'SAVE_OPTIONS_SUCCESS';
export const SAVE_OPTIONS_FAIL = 'SAVE_OPTIONS_FAIL';
export const SAVE_PLAN = 'SAVE_PLAN';
export const SELECT_DEDUCTIBLE_SELECTED = 'SELECT_DEDUCTIBLE_SELECTED';
export const SAVE_PLANS_FINISH = 'SAVE_PLANS_FINISH';
export const RESET_RESULTS_LOADING = 'RESET_RESULTS_LOADING';
export const ADD_INTERACTION = 'ADD_INTERACTION';
export const ADD_INTERACTION_SUCCESS = 'ADD_INTERACTION_SUCCESS';
export const ADD_INTERACTION_FAIL = 'ADD_INTERACTION_FAIL';
export const ASSIGN_LEAD_TO_RPA = 'ASSIGN_LEAD_TO_RPA';
export const ASSIGN_LEAD_TO_RPA_SUCCESS = 'ASSIGN_LEAD_TO_RPA_SUCCESS';
export const ASSIGN_LEAD_TO_RPA_FAIL = 'ASSIGN_LEAD_TO_RPA_FAIL';
