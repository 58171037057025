/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import QuotationHeader from './QuotationHeader';
import QuotationFooter from './QuotationFooter';

export const AppContainerContent = ({ children }) => (
  <Fragment>
    <QuotationHeader />
    <section>{children}</section>
    <QuotationFooter />
  </Fragment>
);

AppContainerContent.propTypes = {
  children: PropTypes.element.isRequired,
};

export default AppContainerContent;
