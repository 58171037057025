import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import { domainResolver } from './utils';

const lng = domainResolver();

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng,
    backend: {
      loadPath: `/${process.env.REACT_APP_PATH}/assets/translations/{{lng}}.json`,
    },
    fallbackLng: lng,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      wait: true,
    },
  });

export default i18n;
