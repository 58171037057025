/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useQuotationTranslation } from '../../../utils/useQuotationTranslation';
import { getPartner } from '../../../utils/fluxes';
import { loadPartner } from '../../service/store/service.actions';
import { useProduct } from '../../../utils/useProduct';
import { saveProduct } from '../../wizard/store/wizard.actions';
import InactivePage from './InactivePage';

const InConstruction = ({ partnerConfig, loadPartner, productSaved, saveProduct }) => {
  const { t } = useQuotationTranslation();
  const partner = getPartner();
  const product = useProduct();

  useEffect(() => {
    if (!productSaved) {
      saveProduct(product);
    }
  }, [productSaved, saveProduct, product]);

  useEffect(() => {
    if (productSaved && partner && !partnerConfig) {
      loadPartner();
    }
  }, [partnerConfig, partner, loadPartner, productSaved]);
  return <InactivePage title={t('inConstruction.title')} header={t('inConstruction.header')} />;
};

const mapStateToProps = (state) => ({
  partnerConfig: state.service.partnerConfig.data,
  productSaved: state.wizard.product,
});

InConstruction.propTypes = {
  partnerConfig: PropTypes.object,
  loadPartner: PropTypes.func,
  productSaved: PropTypes.string,
  saveProduct: PropTypes.func,
};

export default connect(mapStateToProps, { loadPartner, saveProduct })(InConstruction);
