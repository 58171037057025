/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { layout } from '../../main/styles/layout';
import { useQuotationTranslation } from '../../../utils/useQuotationTranslation';
import { StepHeader } from '../../main/components/StepHeader';

const styles = {
  ...layout,
  '.message': {
    margin: '0 auto',
    maxWidth: 395,
    textAlign: 'center',
  },
  '.logo-container': {
    textAlign: 'center',
    '.failed-checklist-img': {
      marginTop: 50,
      height: 110,
    },
  },
};

const FailedChecklist = () => {
  const { t } = useQuotationTranslation();

  return (
    <section css={styles}>
      <div className="logo-container">
        <img
          className="failed-checklist-img"
          alt="contactar"
          src="https://branding.123seguro.com/images/img_resultless_failedPLD.png"
        />
      </div>
      <StepHeader title={t('failedChecklist.title')} />
      <p className="message">{t('failedChecklist.message')}</p>
    </section>
  );
};

export default FailedChecklist;
