/** @jsxRuntime classic */
/** @jsx jsx */
import { ButtonWrapper, Header, colors, respondTo } from '@123-front/ui-kit';
import { jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useBaseUrl } from '../../../utils/useBaseUrl';
import { useFluxObj } from '../../../utils/useFluxObj';
import { useQuotationTranslation } from '../../../utils/useQuotationTranslation';
import { partnerConfigPropTypes } from '../../car/partner/partner.proptypes';
import { redirectToPartner, resetProgress } from '../../wizard/store/wizard.actions';
import MainMenu from './mainMenu';
import MainMenuHeader from './mainMenuHeader';

const styles = (partnerConfig) => ({
  'header:first-of-type': {
    [respondTo('xs', 'sm')]: { position: 'absolute', height: 48 },
    [respondTo('sm')]: { height: 64 },
  },
  '.home-link': {
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    padding: 5,
    height: '100%',
    textAlign: 'center',
    img: {
      height: '100%',
      [respondTo('xs', 'sm')]: {
        maxWidth: '100%',
      },
    },
  },
  '.logo-divider': {
    border: `1px solid ${colors.gray.medium}`,
    height: 35,
    marginLeft: 15,
    marginRight: 15,
  },
  '.logo-123': {
    display: 'inline-block',
    textAlign: 'center',
    img: {
      width: '100%',
    },
  },
  '.partner-logo': {
    display: 'inline-block',
    width: 'max-content',
    height: '80%',
    textAlign: partnerConfig?.show123Logo ? 'left' : 'center',
    img: {
      [respondTo('xs', 'md')]: { marginRight: 20 },
      [respondTo('md')]: { marginRight: 94 },
      ':last-of-type': {
        marginRight: 0,
      },
    },
  },
  '.logo-container': {
    background: partnerConfig?.headerBackgroundColor,
  },
});

const partnerLogoStyles = {
  margin: 'auto',
  padding: 5,
  display: 'flex',
  flexFlow: 'row-reverse',
};

const QuotationHeader = ({
  partnerConfig,
  entryFlux,
  redirectToPartner,
  resetProgress,
  loading,
}) => {
  const { t } = useQuotationTranslation();
  const [show123Logo, set123Logo] = useState(false);
  const [partnerLogos, setPartnerLogos] = useState([]);
  const flux = useFluxObj(entryFlux);
  const baseUrl = useBaseUrl();

  useEffect(() => {
    /* 
      Es importante que compare estrictamente con false para saber que el flujo no es de partner.
      En ese escenario siempre debe mostrar el logo de 123. 
      Si es flujo de partner debe chequear que exista el partner y que muestre el logo de 123 
      dependiendo de la configuración del partner
    */
    if (flux === false) {
      set123Logo(true);
    }
    if (flux && flux.isPartnerFlux === false) {
      set123Logo(true);
    } else if (partnerConfig) {
      set123Logo(partnerConfig.show123Logo);
      if (partnerConfig.logo?.visibility) {
        setPartnerLogos(
          Array.isArray(partnerConfig.logo.path)
            ? partnerConfig.logo.path
            : [partnerConfig.logo.path],
        );
      }
    } else {
      if (!loading) {
        set123Logo(true);
      }
    }
  }, [partnerConfig, baseUrl, flux, set123Logo, setPartnerLogos, loading]);

  const handleChangeFlux = () => {
    if (partnerConfig.logo.allowRedirection) {
      resetProgress();
      redirectToPartner(baseUrl, partnerConfig);
    }
  };

  return (
    <section css={styles(partnerConfig)}>
      <Header menuContent={<MainMenu />} menuEnabled={false} menuTitle={<MainMenuHeader />}>
        <span className="home-link" css={partnerConfig?.logo?.reverse ? partnerLogoStyles : null}>
          {show123Logo && (
            <span className="logo-123">
              <img
                src={t('brandingConfig.headerLogo')}
                alt="123Seguro"
                title="123Seguro"
                width="97px"
                height="36px"
              />
            </span>
          )}
          {show123Logo && partnerLogos.length > 0 && <span className="logo-divider" />}
          {partnerLogos.length > 0 && (
            <ButtonWrapper onClick={handleChangeFlux} className="partner-logo">
              {partnerLogos.map((partnerLogo) => (
                <img
                  src={partnerLogo}
                  alt={partnerConfig.name}
                  title={partnerConfig.name}
                  key={partnerLogo}
                  width="auto"
                  height="auto"
                />
              ))}
            </ButtonWrapper>
          )}
        </span>
      </Header>
    </section>
  );
};

const mapStateToProps = (state) => ({
  partnerConfig: state.service.partnerConfig.data,
  entryFlux: state.wizard.entryFlux,
  loading: state.service.loading,
});

QuotationHeader.propTypes = {
  partnerConfig: partnerConfigPropTypes,
  entryFlux: PropTypes.string,
  redirectToPartner: PropTypes.func.isRequired,
  resetProgress: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default connect(mapStateToProps, {
  redirectToPartner,
  resetProgress,
})(QuotationHeader);
