import { LEGAL_ID_SAVE, SEARCH_LEGAL_ID_SAVE, IS_MAIN_DRIVER_SAVE } from './legalId.action-types';

const INITIAL_STATE = {
  selected: {
    type: null,
    number: null,
  },
  types: [],
  isMainDriver: true,
};

const legalIdReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LEGAL_ID_SAVE:
      return { ...state, selected: action.payload };
    case SEARCH_LEGAL_ID_SAVE:
      return { ...state, selected: action.payload };
    case IS_MAIN_DRIVER_SAVE:
      return { ...state, isMainDriver: action.payload };
    default:
      return state;
  }
};

export default legalIdReducer;
