import { PROVINCE_SAVE } from './justProvince.action-types';

const INITIAL_STATE = {
  selected: null,
};

const justProvinceReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROVINCE_SAVE:
      return {
        ...state,
        selected: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default justProvinceReducer;
