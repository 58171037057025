import axios from 'axios';
import NProgress from 'nprogress';
import { getUUID } from '../../../utils/idsManagement';

NProgress.configure({ easing: 'ease', speed: 800, showSpinner: false });

const uuid = getUUID();
axios.defaults.headers.common['x-client-id'] = uuid;

axios.interceptors.request.use(
  (config) => {
    NProgress.start();
    config.params = config.params || {};
    config.params['clientId'] = uuid;
    return config;
  },
  (error) => {
    NProgress.done();
    console.error('Intercepted request: ', error);
    return Promise.reject(error);
  },
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => {
    NProgress.done();
    return response;
  },
  (error) => {
    console.error('Intercepted response: ', error.response.status, error.response.statusText);
    NProgress.done();
    return Promise.reject(error);
  },
);
