import PropTypes from 'prop-types';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { bodyFont, Breadcrumb, colorTheme } from '@123-front/ui-kit';
import { connect } from 'react-redux';
import { useQuotationTranslation } from '../../../utils/useQuotationTranslation';
import { brandPropTypes } from '../../../modules/car/brand/brand.proptypes';
import { modelPropTypes } from '../../../modules/car/model/model.proptypes';
import { legalIdPropType } from '../../../modules/person/legalId/components/legalIdPropType';
import { versionPropTypes } from '../../../modules/car/version/version.proptypes';
import { getAge } from '../../../utils/date';
import { useBaseUrl } from '../../../utils/useBaseUrl';
import { getPartner } from '../../../utils/fluxes';
import { analyticsPushEvent } from '../../../modules/analytics/store/analytics.actions';
import { resetResultsLoading } from '../../../modules/car/results/store/results.actions';

const styles = {
  '.results-breadcrumb': {
    a: {
      ...bodyFont('sm'),
      color: colorTheme.hintInteractive,
      textTransform: 'capitalize',
    },
    '.level-container:last-child': {
      a: {
        ...bodyFont('sm'),
        color: colorTheme.hintInteractive,
      },
    },
  },
};

const BreadcrumbSteps = ({
  steps,
  completedSteps,
  brand,
  year,
  model,
  plate,
  okm,
  birthdate,
  gender,
  location,
  legalId,
  version,
  alpha2,
  className,
  resetResults,
  analyticsPushEvent,
  resetResultsLoading,
}) => {
  const { t } = useQuotationTranslation();
  const baseUrl = useBaseUrl();

  const zerokm = () => {
    if (okm) {
      return {
        step: 'zerokm',
        sectionName: 'car',
        description: t('zeroKm.newOption'),
        to: `${baseUrl}/${t('routeNames.pages.zerokm')}`,
      };
    } else if (new Date().getFullYear() <= parseInt(year)) {
      return {
        step: 'zerokm',
        sectionName: 'car',
        description: t('zeroKm.usedOption'),
        to: `${baseUrl}/${t('routeNames.pages.zerokm')}`,
      };
    }
    return null;
  };

  const getName = (element) => {
    if (element) {
      return element.name;
    }
    return null;
  };

  const getLegalIdNumber = () => {
    if (legalId && legalId.type) {
      return `${legalId.type.description} ${legalId.number}`;
    }
    return null;
  };
  const breadcrumbLevels = {
    cl: [
      {
        step: 'brand',
        sectionName: 'car',
        description: getName(brand),
        to: `${baseUrl}/${t('routeNames.pages.brand')}`,
      },
      {
        step: 'year',
        sectionName: 'car',
        description: year,
        to: `${baseUrl}/${t('routeNames.pages.year')}`,
      },
      {
        step: 'model',
        sectionName: 'car',
        description: getName(model),
        to: `${baseUrl}/${t('routeNames.pages.model')}`,
      },
      zerokm(),
      {
        step: 'birthdate',
        sectionName: 'person',
        description: t('results.breadcrumb.years', { years: getAge(birthdate) }),
        to: `${baseUrl}/${t('routeNames.pages.birthdate')}`,
      },
      {
        step: 'location',
        sectionName: 'person',
        description: location,
        to: `${baseUrl}/${t('routeNames.pages.location')}`,
      },
      {
        step: 'legalId',
        sectionName: 'person',
        description: getLegalIdNumber(),
        to: `${baseUrl}/${t('routeNames.pages.legalId')}`,
      },
    ],
    ar: [
      {
        step: 'searchByPlate',
        sectionName: 'car',
        description: `${getName(brand)} ${year} ${getName(model)} ${getName(version)}`,
        to: `${baseUrl}/${t('routeNames.pages.searchByPlate')}`,
      },
      {
        step: 'brand',
        sectionName: 'car',
        description: getName(brand),
        to: `${baseUrl}/${t('routeNames.pages.brand')}`,
      },
      {
        step: 'year',
        sectionName: 'car',
        description: year,
        to: `${baseUrl}/${t('routeNames.pages.year')}`,
      },
      {
        step: 'model',
        sectionName: 'car',
        description: getName(model),
        to: `${baseUrl}/${t('routeNames.pages.model')}`,
      },
      {
        step: 'version',
        sectionName: 'car',
        description: getName(version),
        to: `${baseUrl}/${t('routeNames.pages.version')}`,
      },
      {
        step: 'birthdate',
        sectionName: 'person',
        description: t('results.breadcrumb.years', { years: getAge(birthdate) }),
        to: `${baseUrl}/${t('routeNames.pages.birthdate')}`,
      },
      {
        step: 'location',
        sectionName: 'person',
        description: location,
        to: `${baseUrl}/${t('routeNames.pages.location')}`,
      },
    ],
    co: [
      {
        step: 'brand',
        sectionName: 'car',
        description: getName(brand),
        to: `${baseUrl}/${t('routeNames.pages.brand')}`,
      },
      {
        step: 'year',
        sectionName: 'car',
        description: year,
        to: `${baseUrl}/${t('routeNames.pages.year')}`,
      },
      {
        step: 'model',
        sectionName: 'car',
        description: getName(model),
        to: `${baseUrl}/${t('routeNames.pages.model')}`,
      },
      {
        step: 'plate',
        sectionName: 'car',
        description: plate,
        to: `${baseUrl}/${t('routeNames.pages.plate')}`,
      },

      {
        step: 'birthdate',
        sectionName: 'person',
        description: t('results.breadcrumb.years', { years: getAge(birthdate) }),
        to: `${baseUrl}/${t('routeNames.pages.birthdate')}`,
      },
      {
        step: 'gender',
        sectionName: 'person',
        description: gender && t(`gender.options.${gender.toLowerCase()}`),
        to: `${baseUrl}/${t('routeNames.pages.gender')}`,
      },
      {
        step: 'location',
        sectionName: 'person',
        description: location,
        to: `${baseUrl}/${t('routeNames.pages.location')}`,
      },
      {
        step: 'legalId',
        sectionName: 'person',
        description: getLegalIdNumber(),
        to: `${baseUrl}/${t('routeNames.pages.legalId')}`,
      },
    ],
    br: [
      {
        step: 'searchByPlate',
        sectionName: 'car',
        description: `${getName(brand)} ${year} ${getName(model)}`,
        to: `${baseUrl}/${t('routeNames.pages.searchByPlate')}`,
      },
      {
        step: 'brand',
        sectionName: 'car',
        description: getName(brand),
        to: `${baseUrl}/${t('routeNames.pages.brand')}`,
      },
      {
        step: 'year',
        sectionName: 'car',
        description: year,
        to: `${baseUrl}/${t('routeNames.pages.year')}`,
      },
      {
        step: 'model',
        sectionName: 'car',
        description: getName(model),
        to: `${baseUrl}/${t('routeNames.pages.model')}`,
      },
      {
        step: 'version',
        sectionName: 'car',
        description: getName(version),
        to: `${baseUrl}/${t('routeNames.pages.version')}`,
      },
    ],
  };

  const getCompletedModuleSteps = () => {
    return steps
      .map((element) => {
        return element.moduleSteps;
      })
      .flat()
      .slice(0, completedSteps);
  };

  const getBreadcrumbLeves = () => {
    const partner = getPartner();
    return breadcrumbLevels[alpha2]
      .filter((element) => element)
      .filter((element) => getCompletedModuleSteps().includes(element.step))
      .map((breadcrumbLevel) => {
        if (partner !== null) {
          breadcrumbLevel.to = baseUrl;
        }
        return breadcrumbLevel;
      });
  };

  const handleAnalyticsPushEvent = (level) => {
    if (resetResults) {
      resetResultsLoading();
    }
    analyticsPushEvent(
      'trackEvent',
      `edit-${level.sectionName}-${level.step}`,
      'ResultPage',
      null,
      'cotizador',
    );
  };
  return (
    <div css={styles} className={className}>
      <Breadcrumb
        onClick={(level) => {
          handleAnalyticsPushEvent(level);
        }}
        className={'results-breadcrumb'}
        levels={getBreadcrumbLeves(completedSteps, steps)}
      />
    </div>
  );
};

BreadcrumbSteps.defaultProps = {
  className: '',
  levels: [],
  resetResults: false,
};

BreadcrumbSteps.propTypes = {
  levels: PropTypes.arrayOf(PropTypes.object),
  steps: PropTypes.array,
  resetResults: PropTypes.bool,
  completedSteps: PropTypes.number,
  className: PropTypes.string,
  brand: brandPropTypes,
  version: versionPropTypes,
  year: PropTypes.number,
  model: modelPropTypes,
  okm: PropTypes.bool,
  birthdate: PropTypes.instanceOf(Date),
  location: PropTypes.string,
  plate: PropTypes.string,
  gender: PropTypes.string,
  legalId: legalIdPropType,
  alpha2: PropTypes.string,
  analyticsPushEvent: PropTypes.func,
  resetResultsLoading: PropTypes.func,
};

const mapStateToProps = (state) => ({
  steps: state.wizard.steps,
  completedSteps: state.wizard.completedStep,
  brand: state.car.lead.selected.brand,
  year: state.car.lead.selected.year,
  version: state.car.lead.selected.version,
  model: state.car.lead.selected.model,
  okm: state.car.lead.selected.zerokm,
  birthdate: state.person.birthdate.selected,
  location: state.person.location.selected?.name || '',
  legalId: state.person.legalId.selected,
  alpha2: state.main.context.country.alpha2,
  plate: state.car.lead.selected.plate,
  gender: state.person.gender.selected,
});

export default connect(mapStateToProps, { analyticsPushEvent, resetResultsLoading })(
  BreadcrumbSteps,
);
