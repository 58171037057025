import {
  colors,
  titleFont,
  subtitleFont,
  bodyFont,
  respondTo,
  colorTheme,
} from '@123-front/ui-kit';

const title = {
  ...titleFont('md', 'serif'),
  color: colors.gray.darkest,
  textAlign: 'center',
};

export const container = (maxWidth) => ({
  maxWidth: maxWidth,
  paddingRight: 16,
  paddingLeft: 16,
  width: '100%',
  margin: '0 auto',
  boxSizing: 'border-box',
});

export const layout = {
  paddingBottom: 40,

  '.sticky-section': {
    paddingTop: 40,
    zIndex: 1,
    position: 'sticky',
    top: 60,
    width: '100%',
    marginBottom: 16,
    background: 'white',
    [respondTo('sm', 'md')]: {
      position: 'sticky',
      top: 48,
    },
    [respondTo('xs', 'sm')]: {
      position: 'sticky',
      top: -76,
    },
  },

  '.title-section': {
    paddingTop: 40,
    [respondTo('xs', 'sm')]: {
      paddingTop: 24,
    },
  },

  '.input-container': {
    [respondTo('xs', 'sm')]: {
      position: 'sticky',
      top: 0,
      zIndex: 1,
    },
  },

  '.search': {
    '&.filled': {
      '.input-container': {
        '.input-icon': {
          '.udt-icon': {
            width: 16,
            path: {
              fill: colors.gray.dark,
            },
          },
        },
      },
    },
  },

  '.large-container': {
    ...container(1128),
  },

  '.container': {
    ...container(375),
  },

  '.title': {
    ...title,
    marginBottom: 36,
    [respondTo('xs', 'sm')]: {
      marginBottom: 24,
    },
  },

  '.title-with-subtitle': {
    ...title,
    marginBottom: 24,
  },

  '.pretitle': {
    ...subtitleFont('md', 'sans'),
    paddingTop: 40,
    marginBottom: 8,
    whiteSpace: 'pre-line',
    color: colorTheme.textMute,
    textAlign: 'center',
    textTransform: 'uppercase',
  },

  '.subtitle': {
    ...bodyFont('md', 'sans'),
    whiteSpace: 'pre-line',
    color: colorTheme.textMute,
    textAlign: 'center',
    marginBottom: 24,
  },

  '.option-not-selected': {
    width: '100%',
    margin: '0 auto',
    marginTop: 24,
  },

  '.no-results': {
    textAlign: 'center',
  },

  '.continue-button': {
    width: '100%',
  },

  '.continue-whatsapp': {
    marginTop: 24,
  },

  '.loading': {
    marginTop: 24,
  },
};
