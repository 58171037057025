import PropTypes from 'prop-types';

export const brandPropTypes = PropTypes.exact({
  id: PropTypes.string,
  name: PropTypes.string,
  display: PropTypes.string,
  order: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  preferred: PropTypes.bool,
  showOthers: PropTypes.bool,
  savedByMonofield: PropTypes.bool,
  searchNotFound: PropTypes.bool,
});

export const brandReducerPropTypes = PropTypes.exact({
  brands: PropTypes.arrayOf(brandPropTypes),
  loading: PropTypes.bool,
  preferredBrands: PropTypes.arrayOf(brandPropTypes),
  selected: brandPropTypes,
  showOthers: PropTypes.bool,
  reSelected: PropTypes.bool,
  savedByMonofield: PropTypes.bool,
  searchNotFound: PropTypes.bool,
  attempted: PropTypes.bool,
});
